export default function AppTimeline(){

    return(
        <ol class="absolute right-0 w-72 mt-40 border-l border-gray-200 dark:border-gray-700">                  
            <li class="mb-6 ml-4">
                <time class="mt-1 text-xs font-normal leading-none text-gray-400 dark:text-gray-500">The meeting will start shortely</time>
                <h3 class="text-base font-semibold text-gray-900 dark:text-white">You're the seller</h3>
                <p class="mb-4 text-sm font-normal text-gray-500 dark:text-gray-400">A Client asked for a meeting because the product they received seems to be out of order</p>
            </li>
            <li class="mb-6 ml-4">
                <time class="mt-1 text-xs font-normal leading-none text-gray-400 dark:text-gray-500">Pending...</time>
                <h3 class="text-xs font-semibold text-gray-500 dark:text-white">Keep an eye on this timeline during the meeting</h3>
            </li>
            {/* <li class="mb-10 ml-4">
                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">March 2022</time>
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">Marketing UI design in Figma</h3>
                <p class="text-base font-normal text-gray-500 dark:text-gray-400">All of the pages and components are first designed in Figma and we keep a parity between the two versions even as we update the project.</p>
            </li> */}
            {/* <li class="ml-4">
                <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">April 2022</time>
                <h3 class="text-lg font-semibold text-gray-900 dark:text-white">E-Commerce UI code in Tailwind CSS</h3>
                <p class="text-base font-normal text-gray-500 dark:text-gray-400">Get started with dozens of web components and interactive elements built on top of Tailwind CSS.</p>
            </li> */}
        </ol>
    )
}