import React from "react";
import { useState } from "react";

export default function Mod_AddPosition(props) {
  const [showModal, setShowModal] = React.useState(false);
  let [jobpositionlabel, setJobPositionLabel] = useState();
  let [numberofopenings, setNumberOfOpenings] = useState();
  let [teamcontact, setTeamContact] = useState();
  let [roleplay, setRolePlay] = useState("Buyer-Seller");
  let [role, setRole] = useState("Buyer");

  function checkIfInformationIsComplete(){
    if(document.getElementById("grid-job-position-label").value.length != 0 && document.getElementById("grid-job-openings").value.length != 0 && document.getElementById("grid-job-position").value.length != 0){
      setShowModal(false);
      props.parentCallback(jobpositionlabel, teamcontact, roleplay, role, numberofopenings, true)
    }
    else{
      alert("Please fill all information")
    }
  }

  return (
    <>
      <button
        className="bg-vorkv text-white active:bg-pink-600 font-bold uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        New Job Position
      </button>
      {showModal ? (
        <>
          <div
            className=" z-30 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none z-50 "
          >
            <div className="Modal relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    New Job Position
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}


                {/* FORM */}

                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-job-position-label">
                            Job Position Label <br/><span className="text-xxs text-gray-500">(Visible by candidates)</span> 
                        </label>
                        <input onChange={event => setJobPositionLabel(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-job-position-label" type="text" placeholder="HR-PMO"/>{/* border-red-500 */}
                        {/* <p class="text-red-500 text-xxs italic">Please fill out this field.</p> */}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-job-openings">
                            Number Of Openings <br/><span className="text-xxs text-gray-500">(Only visible by you)</span>
                        </label>
                        <input onChange={event => setNumberOfOpenings(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-job-openings" type="number" onKeyDown={ (evt) => (evt.key === 'e' || evt.key === '+' || evt.key === '-' || evt.key === '.') && evt.preventDefault() } placeholder="2"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-job-position">
                          Team Contact <br/><span className="text-xxs text-gray-500">(Only visible by you)</span>
                        </label>
                        <input onChange={event => setTeamContact(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-job-position" type="text" placeholder="Human Resources - Project Management Officer - Paris"/>
                        {/* <p class="text-gray-600 text-xxs italic">Make it as long and as crazy as you'd like</p> */}
                        </div>
                    </div>
{/* 
                    <div className="inline-block relative w-64">
                        <select className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                            <option>Really long option that will likely overlap the chevron</option>
                            <option>Option 2</option>
                            <option>Option 3</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div> */}

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-email">
                              Role-Play Assessment
                          </label>
                          <div className="relative">
                              <select onChange={event => setRolePlay(event.target.value)} className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                <option>Buyer-Seller</option>
                                <option>Pitch</option>
                                <option>Tough Decision</option>
                              </select>
                          </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                          <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-email">
                              Candidate's Role
                          </label>
                          <div className="relative">
                              <select onChange={event => setRole(event.target.value)} className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                <option>Buyer</option>
                                <option>Seller</option>
                                <option>Facilitator</option>
                              </select>
                          </div>
                        </div>
                    </div>

                    {/* <div className="flex flex-wrap -mx-3 mb-2">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-city">
                            City
                        </label>
                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-city" type="text" placeholder="Albuquerque"/>
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-state">
                            State
                        </label>
                        <div className="relative">
                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                            <option>New Mexico</option>
                            <option>Missouri</option>
                            <option>Texas</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-zip">
                            Zip
                        </label>
                        <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-zip" type="text" placeholder="90210"/>
                        </div>
                    </div> */}
                </form>





                

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-vorkv text-white active:bg-emerald-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={checkIfInformationIsComplete}
                  >
                    Add Position
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}