import React, { useEffect, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

import background_img from "../../../../Assets/Images/background/bckg_forhire.jpg"
import animation_img from "../../../../Assets/Images/landing/meeting-animation.gif"
import demo_img from "../../../../Assets/Images/landing/Demo.png"

import { Link } from "react-router-dom";
import Navlinks from "../../../Utils/Navlinks.js";


const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url(${background_img});
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-vorkv opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-4/6 lg:w-5/12 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-vorkv px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 mr-6 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-vorkv font-bold rounded shadow transition duration-300 hocus:bg-vorkv hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => {
  let [demoImgLoaded, setDemoImgLoaded] = useState(null)

  useEffect(()=>{
    const imageToLoad = new Image();
    imageToLoad.src = demo_img;
    imageToLoad.onload = () => {
      setDemoImgLoaded(demo_img);
    }
  },[])


  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <Navlinks color="gray" />
        <TwoColumn>
          <LeftColumn>
            <Notification>A platform to assess and monitor soft skills over the years</Notification>
            <Heading>
              <span> You have candidates <br /> We have Role Plays <br /> A great way to assess</span>
              <br />
              <SlantedBackground>Soft Skills Virtually</SlantedBackground>
            </Heading>
            {/* <PrimaryAction>Read about our virtual assessments</PrimaryAction> */}
            <Link to="/contact"><PrimaryAction>Contact Us</PrimaryAction></Link>
            {/* <Link to="/demo/workspace"><PrimaryAction>Demo</PrimaryAction></Link> */}
          </LeftColumn>
          <RightColumn>
            {/* <Link to="/demo/workspace"> */}
              <div className="flex justify-center items-center relative">
                {demoImgLoaded&&<img src={demoImgLoaded} className="" />}
                <div className="absolute h-full w-full flex justify-center items-center bg-black bg-opacity-50">
                  {/* <div className="text-white text-3xl text-center">View Demo <br /><a className="text-xs">(no registration required)</a></div> */}
                  <div className="text-white text-3xl text-center">Demo not available anymore <br /></div>
                </div>
              </div>
            {/* </Link> */}

            {/* <StyledResponsiveVideoEmbed
            // 374265101
              url="//player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoplay=1&loop=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
