import { Switch, Redirect } from 'react-router';
import { Route } from 'react-router-dom';
import { useEffect } from 'react';
// -
import './Landing.css';
import {TW_Footer} from '../Components/TailwindComponents/TW_Footer';
import Navlinks from "../Components/Utils/Navlinks"
//TREACT
import AnimationRevealPage from "../Components/TailwindTreact/helpers/AnimationRevealPage.js";
import Hero from "../Components/TailwindTreact/components/hero/TwoColumnWithInput.js";
import Hero2 from "../Components/TailwindTreact/components/hero/BackgroundAsImage";
import Hero3 from "../Components/TailwindTreact/components/hero/BackgroundAsImageWithCenteredContent";
import Hero4 from "../Components/TailwindTreact/components/hero/FullWidthWithImage";
import Hero5 from "../Components/TailwindTreact/components/hero/TwoColumnWithFeaturesAndTestimonial";
import Hero6 from "../Components/TailwindTreact/components/hero/TwoColumnWithPrimaryBackground";
import Hero7 from "../Components/TailwindTreact/components/hero/TwoColumnWithVideo";
import Features from "../Components/TailwindTreact/components/features/ThreeColWithSideImage.js";
import MainFeature from "../Components/TailwindTreact/components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "../Components/TailwindTreact/components/features/ThreeColCenteredStatsPrimaryBackground.js";

import JoinTest from '../Components/TailwindTreact/components/forms/SimpleSubscribeNewsletter';
import Feature1 from "../Components/TailwindTreact/components/features/DashedBorderSixFeatures.js";
import Feature2 from "../Components/TailwindTreact/components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Feature3 from "../Components/TailwindTreact/components/features/ThreeColSimple.js";
import Feature4 from "../Components/TailwindTreact/components/features/ThreeColWithSideImage.js";
import Feature5 from "../Components/TailwindTreact/components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Feature6 from "../Components/TailwindTreact/components/features/TwoColSingleFeatureWithStats2.js";
import Feature7 from "../Components/TailwindTreact/components/features/TwoColWithButton.js";
import Feature8 from "../Components/TailwindTreact/components/features/TwoColWithSteps.js";
import Feature9 from "../Components/TailwindTreact/components/features/TwoColWithTwoFeaturesAndButtons.js";
import Feature10 from "../Components/TailwindTreact/components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import Feature11 from "../Components/TailwindTreact/components/features/VerticalWithAlternateImageAndText.js";

import Form1 from "../Components/TailwindTreact/components/forms/SimpleContactUs";
import Form2 from "../Components/TailwindTreact/components/forms/SimpleSubscribeNewsletter";
import Form3 from "../Components/TailwindTreact/components/forms/TwoColContactUsWithIllustration";
import Form4 from "../Components/TailwindTreact/components/forms/TwoColContactUsWithIllustrationFullForm";

import Card1 from "../Components/TailwindTreact/components/cards/PortfolioTwoCardsWithImage";
import Card2 from "../Components/TailwindTreact/components/cards/ProfileThreeColGrid";
import Card3 from "../Components/TailwindTreact/components/cards/TabCardGrid";
import Card4 from "../Components/TailwindTreact/components/cards/ThreeColContactDetails";
import Card5 from "../Components/TailwindTreact/components/cards/ThreeColSlider";
import Card6 from "../Components/TailwindTreact/components/cards/TwoTrendingPreviewCardsWithImage";

import Blog1 from "../Components/TailwindTreact/components/blogs/GridWithFeaturedPost";
import Blog2 from "../Components/TailwindTreact/components/blogs/PopularAndRecentBlogPosts";
import Blog3 from "../Components/TailwindTreact/components/blogs/ThreeColSimpleWithImage";
import Blog4 from "../Components/TailwindTreact/components/blogs/ThreeColSimpleWithImageAndDashedBorder";

import Mobile from "../Components/TailwindTreact/components/cta/DownloadApp.js";

import Testimonial from "../Components/TailwindTreact/components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "../Components/TailwindTreact/components/faqs/SingleCol.js";
import GetStarted from "../Components/TailwindTreact/components/cta/GetStartedLight.js";
import Footer from "../Components/TailwindTreact/components/footers/SimpleFiveColumn";
import Header from '../Components/TailwindTreact/components/headers/light';
import tw from "twin.macro";
const HighlightedText = tw.span`text-vorkv`;



export default function Product(props){

    

    

    // function NavBarCallback(menuChoice){
    //     props.parentCallback(menuChoice);
    // }

    return(
        <div id="Landing">
            <AnimationRevealPage>

            <Navlinks color="black"/>
            Work in progress
            {/* <GetStarted/>

            <Card1 />
            <Card2 />
            <Card3 />
            <Card4 />
            <Card5 />
            <Card6 />

            <Blog1 />
            <Blog2 />
            <Blog3 />
            <Blog4 />

            <Feature9 />
            <Feature11 /> 
            <FeatureStats/>
            <Features 
                heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
            />
            <MainFeature
                heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
            />
            <Testimonial 
                heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
            />
            <Feature8 />  personnages
            
            <Mobile/>
            <Feature7 /> screen
            <Feature3 /> Steps to join
            <Feature6 /> KPIs
            <JoinTest/>
            <Feature1 /> 
            <Feature2 />
            <Feature4 />
            <Feature5 />
            <Feature10 />
            <FAQ
                heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
            /> */}
            
            <Footer />
            </AnimationRevealPage>
            {/* <button onClick={handleLogin}>Log in</button>
            <button onClick={handleSignIn}>Sign in</button> */}
        </div>
    )
}