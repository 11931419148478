import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { Container, ContentWithPaddingXl } from "../../components/misc/Layouts.js";
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings";
import {SectionDescription} from "../../components/misc/Typography";
import { ReactComponent as TwitterIcon} from "../../images/twitter-icon.svg";
import { ReactComponent as LinkedinIcon} from "../../images/linkedin-icon.svg";
import { ReactComponent as GithubIcon } from "../../images/github-icon.svg";

const HeadingContainer = tw.div``
const Heading = tw(SectionHeading)``
const Subheading = tw(SubheadingBase)`text-center mb-3`
const Description = tw(SectionDescription)`mx-auto text-center`

const Cards = tw.div`flex flex-wrap flex-row justify-center sm:max-w-2xl lg:max-w-5xl mx-auto`
const Card = tw.div`mt-24 w-full sm:w-1/2 lg:w-1/3 flex flex-col items-center`
const CardImage = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`w-64 h-64 bg-contain bg-center rounded`}
`
const CardContent = styled.div`
  ${tw`flex flex-col items-center mt-6`}
  .position {
    ${tw`uppercase font-bold tracking-widest text-xs text-primary-500`}
  }
  .name {
    ${tw`mt-1 text-xl font-medium text-gray-900`}
  9
`

const CardLinks = styled.div`
  ${tw`mt-6 flex`}
  .link {
    ${tw`mr-8 last:mr-0 text-gray-400 hocus:text-primary-500 transition duration-300`}
    .icon {
      ${tw`fill-current w-6 h-6`}
    }
  }
`

export default ({
  heading = "Our Team.",
  subheading = "",
  description = "",
  cards = [
    {
      imageSrc: "https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      position: "Co-Founder & CEO & CTO",
      name: "Tariq Axel K.",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        {
          url: "https://linkedin.com",
          icon: LinkedinIcon,
        },
        {
          url: "https://github.com",
          icon: GithubIcon,
        },
      ],
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
      position: "Co-Founder & CPO & CMO",
      name: "Stela M.",
      links: [
        {
          url: "https://twitter.com",
          icon: TwitterIcon,
        },
        {
          url: "https://linkedin.com",
          icon: LinkedinIcon,
        },
        {
          url: "https://github.com",
          icon: GithubIcon,
        },
      ],
    },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
    //   position: "Lead Developer",
    //   name: "Alexandre D.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
    //   position: "Developer",
    //   name: "Antoine C.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1546820389-44d77e1f3b31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=3.45&w=512&h=512&q=80",
    //   position: "Developer",
    //   name: "Mourad K.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1546820389-44d77e1f3b31?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=3.45&w=512&h=512&q=80",
    //   position: "Marketing & Sales ",
    //   name: "Asmae B.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=3.45&w=512&h=512&q=80",
    //   position: "Product Senior Consultant / Advisor",
    //   name: "Nadia N.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&fit=facearea&facepad=2.95&w=512&h=512&q=80",
    //   position: "Technical Senior Consultant / Advisor",
    //   name: "Abdal Ilah K. N.",
    //   links: [
    //     {
    //       url: "https://twitter.com",
    //       icon: TwitterIcon,
    //     },
    //     {
    //       url: "https://linkedin.com",
    //       icon: LinkedinIcon,
    //     },
    //     {
    //       url: "https://github.com",
    //       icon: GithubIcon,
    //     },
    //   ],
    // },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        <HeadingContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          {heading && <Heading>{heading}</Heading> }
          {description && <Description>{description}</Description> }
        </HeadingContainer>
        <Cards>
          {cards.map((card, index) => (
            <Card key={index}>
              <CardImage imageSrc={card.imageSrc} />
              <CardContent>
                <span className="position">{card.position}</span>
                <span className="name">{card.name}</span>
                <CardLinks>
                  {card.links.map((link, linkIndex) => (
                    <a key={linkIndex} className="link" href={link.url}>
                      <link.icon className="icon" />
                    </a>
                  ))}
                </CardLinks>
              </CardContent>
            </Card>
          ))}
        </Cards>
      </ContentWithPaddingXl>
    </Container>
  );
};
