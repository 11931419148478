import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <Router>{/* Router is in the index.js because when if it can't be in the same level i use "useHistory" so putting it a level up in index.js makes "useHistory" not undefined */}
      

    <div className="loadingContainer">
      <div className="loadingScreen"></div>
      <div className="loadingtxt"></div>
    </div>

    
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
