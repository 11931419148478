import React, { useEffect } from 'react';
import { useState } from 'react';
// import Image01 from '../../Assets/Images/animals/penguin.svg';
// import Image02 from '../../Assets/Images/animals/penguin.svg';
// import Image03 from '../../Assets/Images/animals/penguin.svg';
// import Image04 from '../../Assets/Images/animals/penguin.svg';
// import Image05 from '../../Assets/Images/animals/penguin.svg';
import img_Play from '../../../Assets/Images/candidates/play-button.svg';
import img_rejected from '../../../Assets/Images/candidates/x-circle.svg';
import img_accepted from '../../../Assets/Images/candidates/check-circle.svg';
import img_Ongoing from '../../../Assets/Images/candidates/more-horizontal.svg';
import img_info from '../../../Assets/Images/AppTopBar/info_blackandwhite.svg';

import Mod_AddPosition from '../../../Modals/Mod_AddPosition';
import Mod_AssessCandidate from '../../../Modals/Mod_AssessCandidate';
import SwitchCheck from '../../Utils/SwitchCheck';

import ResponsiveVideoEmbed from '../../TailwindTreact/helpers/ResponsiveVideoEmbed';
import styled from "styled-components";
import tw from "twin.macro";
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black`}
  }
`;

function SinglePosition(props) {
  // const [currentcandidatestatus, setCurrentcandidatestatus] = useState(props.application.status)
  // const [currentcandidateName, setCurrentcandidateName] = useState(props.application.CandidateInfo[0].Firstname+" "+props.application.CandidateInfo[0].Lastname)
  const [showVideo, setShowVideo] = React.useState(false);

  function handleAssessmentModification(ApplicationWithOnlyAssessmentChanged){
    props.handleApplicationModification(ApplicationWithOnlyAssessmentChanged)
  }

  // function changeStatus(){
  //   if(currentcandidatestatus=='Ongoing'){setCurrentcandidatestatus("accepted")}
  //   else if(currentcandidatestatus=="accepted"){setCurrentcandidatestatus("rejected")}
  //   else{setCurrentcandidatestatus('Ongoing')}
  // }
  // useEffect(()=>{
  //   console.log(props.application)
  // },[])
  return (
    <>
    <tr key={props.jobposition._id} className={`${props.jobposition.QuickAccess=="True" && "bg-xkyre-100"}`}>
      <td className="p-2 whitespace-nowrap text-sm">
        <div className="flex items-center">
          {/* <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src={props.application.image} width="40" height="40" alt={props.application.name} />
          </div> */}
          <div className="font-medium text-gray-800">{props.jobposition.Jobpositiontitle}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap text-xs">
        <div className="text-left">{props.jobposition.Teamcontact}</div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <div className="text-center">
          {props.jobposition.Approvalgoal}
            {/* onClick={()=>setShowVideo(true)} */}
        </div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <div className={`text-center ${props.jobposition.StepsKPIs.Added+props.jobposition.StepsKPIs.Scheduled==0 && "text-gray-500"}`}>{props.jobposition.StepsKPIs.Added+props.jobposition.StepsKPIs.Scheduled}</div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
        <div className={`text-center ${props.jobposition.StepsKPIs.Assessed==0 && "text-gray-500"}`}>{props.jobposition.StepsKPIs.Assessed}</div>
      </td>

      <td className="p-2 whitespace-nowrap text-sm">
        <div className="text-center">
          {/* {props.jobposition.Approvalgoal < props.jobposition.StepsKPIs.Approved ? <a className="text-center text-green-700">{props.jobposition.StepsKPIs.Approved}</a>:<a className="text-center text-red-500">{props.jobposition.StepsKPIs.Approved}</a>}
          /{props.jobposition.StepsKPIs.Rejected} */}
          <a className={`text-center ${props.jobposition.StepsKPIs.Approved==0 ? "text-gray-500":"text-green-700"}`}>{props.jobposition.StepsKPIs.Approved}</a>
          /<a className={`text-center ${props.jobposition.StepsKPIs.Rejected==0 ? "text-gray-500":"text-red-700"}`}>{props.jobposition.StepsKPIs.Rejected}</a>
        </div>
      </td>

      <td className="p-2 whitespace-nowrap text-xs float-right">
          {/* {props.application.Assessmentscore.status=='Notstarted'? <></> : <Mod_AssessCandidate Candidate={currentcandidateName} application={props.application} handleAssessmentModification={handleAssessmentModification} isToggleTrue={props.application.Decision=='Approved'?true:false} id={props.application.id} leftChoice={img_rejected} rightChoice={img_accepted} image={true}/>} */}
          {/* <button
          className="bg-white text-black active:bg-pink-600  uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => props.selectedPositionParentCallback(props.jobposition._id)}
          >
            Quick Access
          </button> */}

          <button
          className="bg-white text-black active:bg-pink-600  uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
          onClick={() => props.selectedPositionParentCallback(props.jobposition._id)}
          >
            View
          </button>
        </td>
      </tr>


        </>
  )
}


function AppJobPositions(props) {
  let [showAllJobPositions,setShowAllJobPositions]= useState(props.showAllJobPositions);

  function handleShowAllJobPositions(DisplayOrNot){
    setShowAllJobPositions(DisplayOrNot)
    props.setShowAllJobPositions(DisplayOrNot)
  }

  return (
    <div className="AppCandidates col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200">
      
      <header className="px-5 py-4 border-b border-gray-100 flex items-center justify-between">
        <h2 className="font-semibold text-gray-500 text-sm">Select a job position to review the candidates</h2>
        {props.JobPositionId=="" && <Mod_AddPosition parentCallback={props.handlePositionCreation}/>}
      </header>
      <div className="p-3 overflow-scroll h-1/2">
        {/* Table */}
        <div className="overflow-x-auto ">
          <table className="table-auto w-full  ">
            {/* Table header */}
            <thead className="font-semibold text-xxs uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Position Label</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Team Contact</div>
                </th>
                {/* <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Role Play Assessment</div>
                </th> */}
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Openings</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Candidates to be assessed</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Candidates To Review</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Approved/Rejected</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {
                // props.JobPositionId!="" ? <div className="p-2 text-xxs text-gray-500">Select a job position on the right panel</div> : 
                showAllJobPositions ? 
                props.jobpositions.map(jobposition => {
                  return <SinglePosition selectedPositionParentCallback={props.selectedPositionParentCallback} jobposition={jobposition} handlePositionModification={props.handlePositionModification}/>
                })
                :
                props.jobpositions.slice(0,3).map(jobposition => {
                  return <SinglePosition selectedPositionParentCallback={props.selectedPositionParentCallback} jobposition={jobposition} handlePositionModification={props.handlePositionModification}/>
                })
              } 

            </tbody>

          </table>
        
            <div className="flex justify-center w-full mt-4">
                {
                  showAllJobPositions ? 
                  <button className="bg-gray-200 text-black active:bg-pink-600  uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                  onClick={() => handleShowAllJobPositions(false)}>
                    Show Less
                  </button>
                  :
                  <button className="bg-gray-200 text-black active:bg-pink-600  uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
                  onClick={() => handleShowAllJobPositions(true)}>
                    Show All Job Positions
                  </button>
                }
                    
              </div>
        </div>

      </div>
    </div>
  );
}

export default AppJobPositions;