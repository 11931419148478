import React from 'react';
import { useState, useEffect } from 'react';

// Import utilities
// import { tailwindConfig } from '../../utils/Utils';
import resolveConfig from 'tailwindcss/resolveConfig';


// import { useEffect } from 'react/cjs/react.development';



import BarChart_1 from "./BarChart_1"
// let BarChart_1 = React.lazy(() => import('./BarChart_1'));

const tailwindConfig = () => {
    // Tailwind config
    return resolveConfig('./src/css/tailwind.config.js')
}

export default function AppKPI_1(props) {
  let [chartData, setChartData]=useState({});

  useEffect(()=>{
    let data;
    if(!props.jobpositions || props.jobpositions.length==0){
      data = {
        Added: [0],
        Scheduled: [0],
        Assessed:[0],
        Reviewed:[0],
        Decision:[0]
      }
    }
    else{
      data = {
        Added: [props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Added, 0)],
        Scheduled: [props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Scheduled, 0)],
        Assessed:[props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Assessed, 0)],
        Reviewed:[props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Reviewed, 0)],
        Decision:[props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Approved + next.StepsKPIs.Rejected, 0)]
      }
    }
      
      setChartData(
        {
          labels: ['Status'],
          datasets: [
            {
              label: 'Candidates Added, Assessment Scheduling Ongoing',
              data: data.Added,
              backgroundColor: tailwindConfig().theme.colors.indigo[200],
              hoverBackgroundColor: tailwindConfig().theme.colors.indigo[300],
              barPercentage: 1,
              categoryPercentage: 1,
              minBarLength:5,
            },
            {
              label: 'Scheduling done, Assessment Ongoing ',
              data: data.Scheduled,
              backgroundColor: tailwindConfig().theme.colors.indigo[500],
              hoverBackgroundColor: tailwindConfig().theme.colors.indigo[600],
              barPercentage: 1,
              categoryPercentage: 1,
              minBarLength:5,
            },
            {
              label: 'Assessment done, HR review Ongoing',
              data: data.Assessed,
              backgroundColor: tailwindConfig().theme.colors.indigo[800],
              hoverBackgroundColor: tailwindConfig().theme.colors.indigo[900],
              barPercentage: 1,
              categoryPercentage: 1,
              minBarLength:5,
            },
            {
              label: 'HR review done, Decision Ongoing',
              data: data.Reviewed,
              backgroundColor: tailwindConfig().theme.colors.green[400],
              hoverBackgroundColor: tailwindConfig().theme.colors.green[500],
              barPercentage: 1,
              categoryPercentage: 1,
              minBarLength:5,
            },
            {
              label: 'Decision done',
              data: data.Decision,
              backgroundColor: tailwindConfig().theme.colors.gray[400],
              hoverBackgroundColor: tailwindConfig().theme.colors.gray[300],
              barPercentage: 1,
              categoryPercentage: 1,
              minBarLength:5,
            },
          ],
        }
      )
    
  },[props.jobpositions])

  return (
    <>
    {chartData.labels && 
      <div className="col-span-full w-2/3 max-h-36 mr-1 xl:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200 scale-50">
        <header className="px-5 py-4 border-b border-slate-100">
          <h2 className="font-semibold text-xs text-gray-500">Current Candidates Status</h2>
        </header>
        <div className="px-5 py-3">
          <div className="flex items-center justify-start">
            <div className="text-xl font-bold text-gray-800 mr-2">{props.jobpositions.reduce((total, next) => total + next.Total, 0)}</div>
            <div className="text-xxs font-bold text-gray-500 mr-2">Candidates</div>
            {/* <div className="text-sm font-semibold text-white px-1.5 bg-yellow-500 rounded-full">-22%</div> */}
          </div>
        </div>
        {/* Chart built with Chart.js 3 */}
        <div className="grow">
          {/* Change the height attribute to adjust the chart height */}
          <BarChart_1 data={chartData} width={595} height={48} />
        </div>
      </div>
      }
    </>
  );
}
