import React, { useEffect } from 'react';
import { useState } from 'react';
// import Image01 from '../../Assets/Images/animals/penguin.svg';
// import Image02 from '../../Assets/Images/animals/penguin.svg';
// import Image03 from '../../Assets/Images/animals/penguin.svg';
// import Image04 from '../../Assets/Images/animals/penguin.svg';
// import Image05 from '../../Assets/Images/animals/penguin.svg';
import img_Play from '../../../Assets/Images/candidates/play-button.svg';
import img_rejected from '../../../Assets/Images/candidates/x-circle.svg';
import img_accepted from '../../../Assets/Images/candidates/check-circle.svg';
import img_maybe from '../../../Assets/Images/candidates/help-circle.svg';
import img_Scheduled from '../../../Assets/Images/candidates/more-horizontal.svg';
import img_Added from '../../../Assets/Images/candidates/more-horizontal.svg';
import img_info from '../../../Assets/Images/AppTopBar/info_blackandwhite.svg';

import Mod_AddCandidate from '../../../Modals/Mod_AddCandidate';
import Mod_AssessCandidate from '../../../Modals/Mod_AssessCandidate';
import SwitchCheck from '../../Utils/SwitchCheck';

import ResponsiveVideoEmbed from '../../TailwindTreact/helpers/ResponsiveVideoEmbed';
import styled from "styled-components";
import tw from "twin.macro";
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black`}
  }
`;

function SingleApplication(props) {
  // const [currentcandidatestatus, setCurrentcandidatestatus] = useState(props.application.status)
  const [currentcandidateName, setCurrentcandidateName] = useState(props.application.CandidateInfo[0].Firstname+" "+props.application.CandidateInfo[0].Lastname)
  const [showVideo, setShowVideo] = React.useState(false);

  function handleAssessmentModification(ApplicationWithOnlyAssessmentChanged){
    props.handleApplicationModification(ApplicationWithOnlyAssessmentChanged)
  }

  // function changeStatus(){
  //   if(currentcandidatestatus=='Ongoing'){setCurrentcandidatestatus("accepted")}
  //   else if(currentcandidatestatus=="accepted"){setCurrentcandidatestatus("rejected")}
  //   else{setCurrentcandidatestatus('Ongoing')}
  // }
  // useEffect(()=>{
  //   console.log(props.application)
  // },[])
  return (
    <>
    <tr key={props.application._id}>
      <td className="p-2 whitespace-nowrap text-xs">
        <div className="flex items-center">
          {/* <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src={props.application.image} width="40" height="40" alt={props.application.name} />
          </div> */}
          <div className="font-medium text-gray-800">{props.application.CandidateInfo[0].Firstname} {props.application.CandidateInfo[0].Lastname}</div>
        </div>
      </td>
      <td className="p-2 whitespace-nowrap text-xs">
        <div className="text-left">{props.application.CandidateInfo[0].Email}</div>
      </td>
      <td className="p-2 whitespace-nowrap text-xs">
        <div className="flex items-center justify-center">
          {Number(props.application.Assessmentstatus.substr(0,2))<2 ? <img className="rounded-full" src={Number(props.application.Assessmentstatus.substr(0,2))==0 ? img_Added : img_Scheduled} width="20" height="20" alt={props.application.CandidateInfo[0].Lastname} />:
                                      <img className="rounded-full" onClick={()=>setShowVideo(true)} src={img_Play} width="20" height="20" alt={props.application.CandidateInfo[0].Lastname} />}
            {/* onClick={()=>setShowVideo(true)} */}
        </div>
      </td>
      <td className="p-2 whitespace-nowrap text-sm">
      {Number(props.application.Assessmentstatus.substr(0,2))>=3 ? <div className="flex justify-center align-center text-gray-600">{props.application.Assessmentscore.score}/{props.application.Assessmentscore.total} <img className="ml-3" src={img_info} width="12" height="12" title={props.application.Assessmentscore.comment==""?"You didn't write a comment in the review":`Your comment : ${props.application.Assessmentscore.comment}`}/></div>:<></>}
      </td>
      <td className="p-2 whitespace-nowrap text-xs">
        {/* <button className="flex " onClick={changeStatus}>
          {candidate.assessment=='Ongoing'? <></> :
          currentcandidatestatus=='Ongoing'? <div className="flex"><img className="rounded-full mx-2" src={img_Ongoing} width="30" height="30" alt={candidate.name} /><img className="rounded-full filter grayscale" src={img_accepted} width="15" height="15" alt={candidate.name} /><img className="rounded-full  filter grayscale" src={img_rejected} width="15" height="15" alt={candidate.name} /></div>:
          currentcandidatestatus=='accepted'? <div className="flex"><img className="rounded-full mx-2" src={img_accepted} width="30" height="30" alt={candidate.name} /><img className="rounded-full filter grayscale" src={img_Ongoing} width="15" height="15" alt={candidate.name} /><img className="rounded-full filter grayscale" src={img_rejected} width="15" height="15" alt={candidate.name} /></div>:
                                              <div className="flex"><img className="rounded-full mx-2" src={img_rejected} width="30" height="30" alt={candidate.name} /><img className="rounded-full filter grayscale" src={img_Ongoing} width="15" height="15" alt={candidate.name} /><img className="rounded-full filter grayscale" src={img_accepted} width="15" height="15" alt={candidate.name} /></div>}
        </button> */}

        {/* {props.application.Decision=='Notstarted' || props.application.Decision=='Ongoing'? <></> :
          <SwitchCheck isToggleTrue={props.application.Decision=='Approved'?true:false} id={props.application.id} leftChoice={img_rejected} rightChoice={img_accepted} image={true}/>
        } */}
        <label class="flex justify-center align-center text-gray-800" for="flexSwitchCheckDefault">
        {Number(props.application.Assessmentstatus.substr(0,2))<3 ? <></> :
          props.application.Decision=='Ongoing'?<img className="rounded-full mx-1" src={img_maybe} width="25" height="25" alt="Decision" />:<img className="rounded-full mx-1" src={props.application.Decision=='Approved'?img_accepted:img_rejected} width="25" height="25" alt="Decision" />
        }
        </label>

        <div className="text-left font-medium"></div>
      </td>

      <td className="p-2 whitespace-nowrap text-xs float-right">
        {Number(props.application.Assessmentstatus.substr(0,2))<2? <></> : <Mod_AssessCandidate Candidate={currentcandidateName} application={props.application} handleAssessmentModification={handleAssessmentModification} isToggleTrue={props.application.Decision=='Approved'?true:false} id={props.application.id} leftChoice={img_rejected} rightChoice={img_accepted} noChoice={img_maybe} image={true}/>}
      </td>
    </tr>
    


          {/* Video */}
          {!showVideo ? <div></div> :
          <>
          <div
            className="z-30 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none z-50 "
          >
            <div className="relative w-1/2 mx-auto max-w-3xl">
              
              <div className="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-1xl font-semibold">
                    Candidate : {currentcandidateName}
                  </h3>
                  {/* <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    // onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button> */}
                </div>
                
                <div className="relative p-2 flex-auto h-full w-full">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                    Usefull links to use while watching the video : <br/>
                    - Reminder of the purpose of the role-Play : http links
                    - Reminder of the purpose of each role : http links
                    

                  </p>  */}



                <StyledResponsiveVideoEmbed
                // 374265101
                  url={props.application.Assessmenturl}
                  background="transparent"
                />

                
                </div>
                
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>setShowVideo(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-5 fixed inset-0 z-40 bg-black"></div>
        </>}
        </>
  )
}


function AppCandidates(props) {

  return (
    <div className="AppCandidates col-span-full xl:col-span-6 bg-white shadow-lg rounded-lg border border-gray-200">
      
      <header className="px-5 py-4 border-b border-gray-100 flex items-center justify-between">
        <h2 className="font-semibold text-gray-500 text-sm">Review your candidates for this job position</h2>
        <div className="">
          <button className="bg-gray-500 text-white active:bg-pink-600 font-bold uppercase text-xxs px-3 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button"
            onClick={() => props.selectedPositionParentCallback("")} >
            Return to positions
          </button>

          {props.JobPositionId!="" && <Mod_AddCandidate handleApplicationCreation={(firstname, lastname, email)=>{props.handleApplicationCreation(firstname, lastname, email)}}/>}
        </div>
      </header>
      <div className="p-3 overflow-scroll h-1/2">

        {/* Table */}
        <div className="overflow-x-auto ">
          <table className="table-auto w-full  ">
            {/* Table header */}
            <thead className="font-semibold text-xxs uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Assessment Video</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Assessment Score</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-center">Decision</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-100">
              {
                props.JobPositionId=="" ? <div className="p-2 text-xxs text-gray-500">Select a job position on the right panel</div> : 
                props.applications.map(application => {
                  return <SingleApplication application={application} handleApplicationModification={props.handleApplicationModification}/>
                })
              } 
            </tbody>

          </table>
        </div>
      </div>
    </div>
  );
}

export default AppCandidates;