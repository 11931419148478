import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import image_step1 from '../../../../Assets/Images/landing/step1.jpg'
import image_step2 from '../../../../Assets/Images/landing/step2.jpg'
import image_step3 from '../../../../Assets/Images/landing/step1_a.jpg'

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-0 lg:py-4`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-2 md:flex justify-center items-center py-4 lg:py-16`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-4xl font-bold text-vorkv`;//text-gray-900`;
const Description = tw.p`mt-2 text-2xl leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-vorkv font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-vorkv`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-vorkv fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-vorkv fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-vorkv fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-vorkv fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:image_step1,
        // "https://images.unsplash.com/photo-1550699026-4114bbf4fb49?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=632&q=80",
      subtitle: "Step 1",
      title: "Group simulations with other candidates",
      description:
      "Simulate real life situations and interactions to see how the candidate may react. The simulation takes place virtually with a random set of other candidates applying for other positions.",
      url: "https://timerse.com"
    },

    {
      imageSrc:image_step2,
        // "https://images.unsplash.com/photo-1543423924-b9f161af87e4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80",
      subtitle: "Step 2",
      title: "Recorded both for HR and the future team",
      description:
        "We send a video of the simulation to HR in order to assess the soft skills demonstrated during the simulation. We also send it to the team the candidate may join, it gives them a better ground to make decisions",
      url: "https://timerse.com"
    },

    {
      imageSrc:image_step3,
        // "https://images.unsplash.com/photo-1509824227185-9c5a01ceba0d?ixlib=rb-1.2.1&auto=format&fit=crop&w=658&q=80",
      subtitle: "Step 3",
      title: "Long-term decisions for your employees",
      description:
        "Record regurarly and keep the action assessments of your employees to monitor their progression over the years. This helps make decisions on future roles they may be suited for.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        {/* <HeadingInfoContainer>
          <HeadingTitle>Room Modes</HeadingTitle>
          <HeadingDescription>
            Private sessions, library style, Solo streamer, multiple modes to boost your productivity
          </HeadingDescription>
        </HeadingInfoContainer> */}

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
