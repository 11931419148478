import React from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4   text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-vorkv`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-vorkv">get in touch</span><wbr/> with us.</>,
  description = "If you prefer to send us a mail directly :📮: contact@xkyre.com",
  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [showLoadingModal, setShowLoadingModal] = React.useState(false);
  const [showThanksModal, setShowThanksModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setShowLoadingModal(true);

    emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_CONTACTUS_ID, form.current, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        setShowLoadingModal(false);
        setShowThanksModal(true);
        // console.log(result.text);
      }, (error) => {
        setShowLoadingModal(false);
        setShowErrorModal(true);
          // console.log(error.text);
      });
  };

  return (
    <>
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} ref={form} onSubmit={sendEmail}>
              <Input className="p-2" type="email" name="email" placeholder="Your Email Address" />
              <Input className="p-2" type="text" name="first_name" placeholder="First Name" />
              <Input className="p-2" type="text" name="last_name" placeholder="Last Name" />
              <Input className="p-2" type="text" name="subject" placeholder="Subject" />
              <Textarea className="p-2"  name="message" type="text" placeholder="Your Message Here" />
              <SubmitButton type="submit" value="Send">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>





{showThanksModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl text-black font-semibold">
                    Contact Us
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-lg leading-relaxed">
                    🍬 Thank you for your message ! <br/><br/>

                    We'll get back to you as soon as possible ⏰<br/><br/>

                    In the meantime, you may go back to the landing page to join the Beta Tester community 🥸 
                  </p>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowThanksModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}


{showErrorModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl text-black font-semibold">
                  Contact Us
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-lg leading-relaxed">
                    ❎ Error unfortunately ! <br/><br/>

                    Please try again later or send us an email <br/>:📮: <a style={{color:"blue"}}  href="mailto:contact@xkyre.com">contact@xkyre.com</a> <br/><br/>
                  </p>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowErrorModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}



{showLoadingModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-60 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-lg leading-relaxed">
                  <br/> ⏳ We are querying our servers ... <br/>

                  </p>

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
        </>
      ) : null}



    </>
  );
};
