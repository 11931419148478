import React from "react";
import { useState } from "react";

export default function Account(props) {
  let [jobpositionlabel, setJobPositionLabel] = useState();
  let [numberofopenings, setNumberOfOpenings] = useState();
  let [jobposition, setJobPosition] = useState();
  let [roleplay, setRolePlay] = useState("Buyer-Seller");
  let [role, setRole] = useState("Buyer");

  function checkIfInformationIsComplete(){
    if(document.getElementById("grid-job-position-label").value.length != 0 && document.getElementById("grid-job-openings").value.length != 0 && document.getElementById("grid-job-position").value.length != 0){
      props.parentCallback(jobpositionlabel, roleplay, role, numberofopenings, true)
    }
    else{
      alert("Please fill all information")
    }
  }

  return (
        <>
          <div
            className="h-auto w-full justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none "
          >
            <div className="ml-20 relative w-2/3 h-auto bg-white rounded">
              {/*content*/}
              <div className="border-0 relative flex flex-col w-full outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Account Management
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    // onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}


                {/* FORM */}

                <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                            First Name 
                        </label>
                        <input onChange={event => setJobPositionLabel(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" defaultValue="Jane"/>{/* border-red-500 */}
                        {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                            Last Name  
                        </label>
                        <input onChange={event => setJobPositionLabel(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-last-name" type="text" defaultValue="Doe"/>{/* border-red-500 */}
                        {/* <p class="text-red-500 text-xs italic">Please fill out this field.</p> */}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-email">
                          Email
                        </label>
                        <input onChange={event => setJobPosition(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" defaultValue="jane.doe@company.com"/>
                        {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-subscription">
                          Subscription
                        </label>
                        <input onChange={event => setJobPosition(event.target.value)} value="Beta Tester" disabled className="appearance-none block w-full bg-gray-200 text-gray-400 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-subscription" type="text" placeholder="Beta Tester"/>
                        {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                        </div>
                    </div>

                    
                </form>





                

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  {/* <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>props.parentCallback("", "", "", "", false)}
                  >
                    Cancel
                  </button> */}
                  <button
                    className="bg-vorkv text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={checkIfInformationIsComplete}
                  >
                    Save Modifications
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 z-40 bg-black"></div> */}
        </>
    //   ) : null}
    // </>
  );
}