import TextLoop from "react-text-loop";

import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";

import Header from "../headers/light.js";

import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import DesignIllustration from "../../../../Assets/Images/Hero.svg";
import CustomersLogoStripImage from "../../images/customers-logo-strip.png";

import { Link } from "react-router-dom";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto`; /*py-20 md:py-24 px-20 md:px-24*/
const LeftColumn = tw.div`relative lg:w-1/2 text-center max-w-lg mx-auto lg:max-w-none lg:text-left py-10 md:py-10`;
const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end`;

const Heading = tw.h1`font-bold text-4xl md:text-4xl lg:text-5xl xl:text-5xl text-gray-900 leading-tight`;
const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-lg`;

const Actions = styled.div`
  //${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  // input {
  //   ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  // }
  // button {
  //   ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-vorkv text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-full sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  // }
  ${tw`flex flex-col items-center justify-center max-w-md text-center mx-auto lg:mx-0 my-8`}
  #bluebutton {
    ${tw`w-40 bg-vorkv text-gray-100 font-bold mr-2 my-1 rounded-full py-4 flex items-center justify-center sm:leading-none focus:outline-none hover:bg-vorkv transition duration-300`}
  }
  #whitebutton {
    ${tw`w-40 bg-gray-100 text-vorkv font-bold mr-2 my-1 rounded-full py-4 flex items-center justify-center sm:leading-none focus:outline-none transition duration-300`}
  }
`;

const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const CustomersLogoStrip = styled.div`
  ${tw`mt-12 lg:mt-20`}
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold text-gray-500`}
  }
  img {
    ${tw`mt-4 w-full lg:pr-16 xl:pr-32 opacity-50`}
  }
`;

export default ({ roundedHeaderButton }) => {
  return (
    <>
      <Header roundedHeaderButton={roundedHeaderButton} />
      <Container>
        <Heading tw="flex mt-0 justify-center md:justify-start max-w-screen-xl mx-auto">
            {/* Someone to <span tw="text-vorkv">work with</span> */}
            <span tw="text-lg text-gray-500 text-center"> A platform to assess and monitor soft skills over the years</span> 
        </Heading>
        <TwoColumn>
          <LeftColumn>
            <Heading>
            {/* Someone to <span tw="text-vorkv">work with</span> */}
            {/* Assess current and future employees <span tw="text-vorkv">Soft Skills</span> through Action Assessments */}
            {/* Assess soft skills with <span tw="text-vorkv">group roleplay simulations</span>.  */}
            {/* <br/> */}
            {/* New & More challenging <span tw="text-vorkv">soft skills interviews</span>, No more vague questions like:<br/><br/> 
            <span tw="text-vorkv">
              <TextLoop interval={1000}>
                <span>Tell me about a time where ...</span>
                <span>How would you handle ...</span>
                <span>What would you do if ...</span>
                <span>How would you feel ...</span>
              </TextLoop>
            </span> */}
            {/*  */}
            A group of candidate, a timer, New <span tw="text-vorkv">soft skills interviews</span><br/><br/> 
            Assess  <span tw="text-vorkv">
              <TextLoop interval={1000}>
                <span>Decision making</span>
                <span>Stress capacity</span>
                <span>Diplomacy</span>
                <span>Facilitation</span>
                <span>Negociation</span>
                <span>Story telling</span>
                <span>Persuasion</span>
              </TextLoop>
            </span>

            {/* Tell me about a time where ... "How would you handle ...", "What would you do if ...", "How would you feel ..." */}
            {/* Monitor, hire and make decisions based on proven skills. */}
            {/* <Link to="/workspace">What are Action Assessments ?</Link> */}

            {/* <CustomersLogoStrip>
              <p>Our TRUSTED Customers</p>
              <img src={CustomersLogoStripImage} alt="Our Customers" />
            </CustomersLogoStrip> */}

            </Heading>
            <Paragraph>
            {/* <br/> */}
            <Actions>
              {/* <input type="text" placeholder="Your E-mail Address (not mandatory)" /> */}
              <Link to="/demo/workspace/"><button id="whitebutton">Try for free</button></Link>
              <Link to="/workspace"><button id="bluebutton">Connect</button></Link>
            </Actions>
            Try a demo version without registration (non-persistent data) <br/>
            We're here for any questions : <span tw="text-vorkv"><a href="mailto:abc@etc.com">Contact us</a></span>
            
            
            </Paragraph>
            
          </LeftColumn>
          <RightColumn>
            <IllustrationContainer>
              <embed tw="min-w-0 w-full max-w-lg xl:max-w-3xl" src={DesignIllustration} alt="Design Illustration" />
            </IllustrationContainer>
          </RightColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
};
