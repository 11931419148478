import React from "react";
// import AppCamsLiveroom from "./TODELETE/AppCamsLiveroom";
// import AppCamsStudyWithMe from "./TODELETE/AppCamsStudyWithMe";
// import AppCamsMenu from './TODELETE/AppCamsMenu';
// import AppQuote from "./AppQuote";
import AppTopBar from "./AppTopBar";
import AppKPI_1 from './HRWorkspace/AppKPI_1'
import AppKPI_2 from './HRWorkspace/AppKPI_2'
import AppCandidates from "./HRWorkspace/AppCandidates.js"
import AppJobPositions from "./HRWorkspace/AppJobPositions"
import AppTimeline from "./CandidateWorkspace/AppTimeline";
import { useHistory } from "react-router-dom";
import { Route } from "react-router";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";

//API AXIOS CONNECTION TO BACKEND
import roomService from "../../Services/room.service";
import applicationService from "../../Services/application.service"
import candidateService from "../../Services/candidate.service"
import jpService from "../../Services/jobposition.service";
import AppVideoConference from "./CandidateWorkspace/AppVideoConference";
import AppJPPanel from "./HRWorkspace/AppJPPanel";




//props: test, JobPositionId, currentJPList
export default function AppGrid(props){
  // let [roomID, setRoomID] = useState()
  // let [roomsdata, setRoomsData] = useState()
  // let history = useHistory();
  // let BackendConnectionErrorMessage = "A problem occurred when connecting to our servers"
  let [filteredApplications, setFilteredApplications] = useState([])
  let [jobApplicationData, setJobApplicationData] = useState();
  let [JPData, setJPData] = useState([]);

  const [showModalPosition, setShowModalPosition] = useState(false);
  const [showErrorModal, setShowErrorModal]  = useState(false);
  let [showAllJobPositions,setShowAllJobPositions]= useState(false);

  
  // FOR DEMO PURPOSE
  let [nextApplicationId, setNextApplicationId] = useState(11);
  let [nextPositionId, setNextPositionId] = useState(7);

 
 //Job Position DATA INTERACTION
 useEffect(()=>{ //TempSolution : in order to rerender when changing activity
  console.log();
}, [JPData])

async function readJobPositionData(){
  //Checking first if it's a user only testing or connecting
  if(props.test=="false"){
      jpService.findAllByIdList(props.currentJPList)
      .then((res) => {
          if(res.status!=200){
              setJPData([])
              alert("User not found, you'll be redirected to the Signup page"); window.location.href='/auth/signup'; 
          }
          else{
              setJPData(res.data);
              // setDataId(res.data[0]._id);
          }
      }).catch((error)=>{
          setShowErrorModal(true)
      });
  }  
  else{
      var data = [
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "SDR",
              Teamcontact: "Matt JAKITA",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:1,
                Assessed:1,
                Reviewed:0,
                Rejected:0,
                Approved:0,
              },
              Total:2,
              Approvalgoal: 4,
              QuickAccess:"False",
              Status: "Ongoing",
              _id:"1"
          },
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "BDR",
              Teamcontact: "Matt JAKITA",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:1,
                Assessed:2,
                Reviewed:0,
                Rejected:2,
                Approved:1,
              },
              Total:6,
              Approvalgoal: 2,
              QuickAccess:"True",
              Status: "Ongoing",
              _id:"2"
          },
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "Customer Success",
              Teamcontact: "Judy BRANSON",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:0,
                Assessed:1,
                Reviewed:0,
                Rejected:0,
                Approved:2,
              },
              Total:3,
              Approvalgoal: 1,
              QuickAccess:"False",
              Status: "Ongoing",
              _id:"3"
          },
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "Account Executive",
              Teamcontact: "Leonie LE BAN",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:0,
                Assessed:0,
                Reviewed:0,
                Rejected:0,
                Approved:0,
              },
              Total:0,
              Approvalgoal: 3,
              QuickAccess:"True",
              Status: "Ongoing",
              _id:"4"
          },
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "Setter",
              Teamcontact: "Matt JAKITA",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:0,
                Assessed:0,
                Reviewed:0,
                Rejected:0,
                Approved:0,
              },
              Total:0,
              Approvalgoal: 2,
              QuickAccess:"False",
              Status: "Ongoing",
              _id:"5"
          },
          {
              HrID: "...",
              SubscriptionID: "...",
              Jobpositiontitle: "Closer",
              Teamcontact: "Mel CLAVIER",
              RoleplayID: "1",
              RoleID:"1",
              Listofapplicationid: ["1","2","3"],
              StepsKPIs:{
                Added:0,
                Scheduled:0,
                Assessed:0,
                Reviewed:0,
                Rejected:0,
                Approved:0,
              },
              Total:0,
              Approvalgoal: 1,
              QuickAccess:"False",
              Status: "Ongoing",
              _id:"6"
          },
      ];
      setJPData(data)
  } 
}

async function writeJobPositionData(newjobpositiondata){
  if(props.test=="false"){
      jpService.create(newjobpositiondata)
      .then((res)=>{
          if(res.status==200) {updateCurrentJPList("add", res.data._id)}
      }).catch(err => {
          alert("Connection lost :"+err)
      });
      readJobPositionData();
  }
  else{
      let newId = nextPositionId;
      setNextPositionId(newId+1);

      setJPData([...JPData, {...newjobpositiondata, _id:newId}])
      updateCurrentJPList("add", newId)
  }
}

function updateJobPositionData(jpID, newdata){
  if(props.test=="false") {
    jpService.update(jpID, newdata).then(()=>{
      readJobPositionData();
    })
    .catch((err)=>{
      alert("error updating data :"+err)
    })
  }
  else {
    let JPDataTemp = [...JPData];
    let indexOfPositionToUpdate = JPDataTemp.findIndex(posobj => {return posobj._id==jpID})
    JPDataTemp[indexOfPositionToUpdate] = newdata;
    setJPData(JPDataTemp)
  }
}

async function removeJobPositionData(value){
  if(props.test=="false"){
      jpService.remove(JPData[value]._id).then(()=>{
          updateCurrentJPList("remove", JPData[value]._id)
      })
  }
  else{
      JPData.splice(value,1);
      updateCurrentJPList("remove", JPData[value]._id)
  }
}

useEffect(() => {
  readJobPositionData(); //read data when opening the page
}, []);
useEffect(() => {
  if(props.test=="false"){readJobPositionData()} //read data when currentJPList changes
}, [props.currentJPList]);

function updateCurrentJPList(natureOfModification, idOfElementToModify){
  let newCurrentJPList;

  if(natureOfModification == "add"){
      newCurrentJPList = [...props.currentJPList, idOfElementToModify]
  }
  else if(natureOfModification == "remove"){
      newCurrentJPList = [...props.currentJPList].filter(function(data){return data._id != idOfElementToModify})
  }
  
  if(props.test=="false"){props.updateCurrentJPListCallback(newCurrentJPList)}
}

function AddPosition(usersNewJPName, usersNewJPTeamcontact, usersNewRolePlay, usersNewRole, usersNewGoal, isInformationValid){
  if(!isInformationValid) return

  let newJPData = 
  {
      HrID: props.hrID,
      SubscriptionID: "...",
      Jobpositiontitle: usersNewJPName,//.substr(0, 10),
      Teamcontact: usersNewJPTeamcontact,
      RoleplayID: usersNewRolePlay,
      RoleID:usersNewRole,
      Listofapplicationid: [],
      StepsKPIs:{
        Added:0,
        Scheduled:0,
        Assessed:0,
        Reviewed:0,
        Rejected:0,
        Approved:0,
      },
      Total:0,
      Approvalgoal: usersNewGoal.substr(0,3),
      QuickAccess:"False",
      Status: "Ongoing"
  };
  
  writeJobPositionData(newJPData);
}

function updateJobPositionKPIsBasedOnApplicationModification(currentApplication, nextApplication){
  // currentApplication.Assessmentstatus
  // .findIndex(application => {return application._id == ApplicationWithCandidateInfo._id;})
  let indexOfJobPositionToModify = JPData.findIndex(jp => {return jp._id ==nextApplication.JobpositionID})


  let JPDataTemp = [...JPData];
  if(currentApplication!=null){
    let previousStep = currentApplication.Assessmentstatus.substr(3);
    JPDataTemp[indexOfJobPositionToModify].StepsKPIs[previousStep]--;
  }

  let nextStep = nextApplication.Assessmentstatus.substr(3);
  JPDataTemp[indexOfJobPositionToModify].StepsKPIs[nextStep]++;

  setJPData(JPDataTemp)

  if(props.test=="false"){updateJobPositionData(nextApplication.JobpositionID, JPDataTemp[indexOfJobPositionToModify])}
}

//Application & Candidate DATA INTERACTION

useEffect(()=>{
  if(jobApplicationData){
    setFilteredApplications(
      jobApplicationData.filter(function(data){return data.JobpositionID == props.JobPositionId;})
    )
  }
},[props.JobPositionId])


useEffect(() => {
  readApplicationDataWithCandidateInfo(); //read data when opening the page
}, []);
useEffect(() => {
    if(props.test=="false"){readApplicationDataWithCandidateInfo()} //read data when currentJPList changes
}, [props.currentJPList]);
function readApplicationDataWithCandidateInfo(){
  //Checking first if it's a user only testing or connecting
  if(props.test=="false"){
    applicationService.findAllByJPIdListWithCandidateInfo(props.currentJPList)
    .then((res) => {
        if(res.status!=200){
            setJobApplicationData([])
            setFilteredApplications([])
        }
        else{
            setJobApplicationData(res.data);
            if(props.JobPositionId){setFilteredApplications(
              res.data.filter(function(data){return data.JobpositionID == props.JobPositionId;})
            )}
            // setDataId(res.data[0]._id);
        }
    });


      // readApplicationData().then((applicationData)=>{
      //   readCandidatesData(applicationData).then((candidatesData)=>{
      //     setJobApplicationData(candidatesData);
      //     if(props.JobPositionId){setFilteredApplications(
      //       candidatesData.filter(function(data){return data.jobpositionid == props.JobPositionId;})
      //     )}
      //   })
      // })
  }  
  else{
      var data = [
        {
          _id: 0,
          JobpositionID: '1',
          Assessmentscore: {
            status:'Notstarted',
            score:0,
            total:0,
            detail:{},
          },
          Assessmentstatus: '00_Added', 
          Assessmenturl:'Ongoing',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Margaret',
            Lastname: 'PRIELIESKY',
            Email: 'margaret.p@gmail.com',
          }]
        },
        {
          _id: 1,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Notstarted',
            score:0,
            total:0,
            detail:{},
            comment:""
          },
          Assessmentstatus: '01_Scheduled',
          Assessmenturl:'Ongoing',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Thomas',
            Lastname: 'RAMON',
            Email: 'thomas.r@gmail.com',
          }]
        },
        {
          _id: 2,
          JobpositionID: '3',
          Assessmentscore: {
            status:'Done',
            score:10,
            total:15,
            detail:{
              "Diplomacy":"Excellent",
              "Handling a client":"Poor",
              "Finding Solutions":"Very Good",
            },
            comment:""
          },
          Assessmentstatus: '04_Approved',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Approved',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Liliana',
            Lastname: 'FISTALIPA',
            Email: 'liliana.f@gmail.com',
          }]
        },
        {
          _id: 3,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Done',
            score:11,
            total:15,
            detail:{
              "Facilitating":"Excellent",
              "Handling a client":"Very Good",
              "Finding Solutions":"Fair",
            },
            comment:"Great facilitation techniques, struggles a bit with finding solutions but the team may help her with that",
          },
          Assessmentstatus: '04_Approved',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Approved',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Elisa',
            Lastname: 'PAPALAKO',
            Email: 'elisa.p@gmail.com',
          }]
        },
        {
          _id: 4,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Ongoing',
            score:0,
            total:0,
            detail:{},
            comment:""
          },
          Assessmentstatus: '02_Assessed',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Adam',
            Lastname: 'L.',
            Email: 'adam.l@gmail.com',
          }]
        },
        {
          _id: 5,
          JobpositionID: '3',
          Assessmentscore: {
            status:'Done',
            score:13,
            total:20,
            detail:{
              "Facilitating":"Excellent",
              "Handling a client":"Fair",
              "Finding Solutions":"Very Good",
              "Diplomacy":"Fair",
            },
            comment:"Good at finding solutions & displays leadership, we may teach him how to handle a client"
          },
          Assessmentstatus: '04_Approved',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Approved',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Ismael',
            Lastname: 'GARCIA RAMIREZ',
            Email: 'ismael.gr@gmail.com',
          }]
        },
        {
          _id: 6,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Done',
            score:11,
            total:25,
            detail:{
              "Facilitating":"Poor",
              "Handling a client":"Poor",
              "Decision Making":"Good",
              "Finding Solutions":"Very Good",
              "Diplomacy":"Fair",
            },
            comment:"lack of listening, good at making quick decisions"
          },
          Assessmentstatus: '05_Rejected',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Rejected',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Lavina',
            Lastname: 'RALINAND',
            Email: 'lavina.r@gmail.com',
          }]
        },
        {
          _id: 7,
          JobpositionID: '1',
          Assessmentscore: {
            status:'Ongoing',
            score:0,
            total:0,
            detail:{},
            comment:""
          },
          Assessmentstatus: '02_Assessed',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Murat',
            Lastname: 'ATRAS',
            Email: 'murat.a@gmail.com',
          }]
        },
        {
          _id: 8,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Ongoing',
            score:0,
            total:0,
            detail:{},
            comment:""
          },
          Assessmentstatus: '02_Assessed',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Sylvie',
            Lastname: 'ROLAND',
            Email: 'sylvie.r@gmail.com',
          }]
        },
        {
          _id: 9,
          JobpositionID: '2',
          Assessmentscore: {
            status:'Done',
            score:9,
            total:20,
            detail:{
              "Facilitating":"Poor",
              "Handling a client":"Fair",
              "Finding Solutions":"Excellent",
              "Diplomacy":"Poor",
            },
            comment:"Finds great solutions, but may be a bad influence for the team spirit we are building"
          },
          Assessmentstatus: '05_Rejected',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Rejected',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Raymond',
            Lastname: 'HENRIETTE',
            Email: 'raymond.h@gmail.com',
          }]
        },
        {
          _id: 10,
          JobpositionID: '3',
          Assessmentscore: {
            status:'Ongoing',
            score:0,
            total:0,
            detail:{},
            comment:""
          },
          Assessmentstatus: '02_Assessed',
          Assessmenturl:'//Player.vimeo.com/video/409315117?title=0&portrait=0&byline=0&autoPlay=1&loop=0&responsive=1',
          Decision:'Ongoing',
          CandidateID:'',
          CandidateInfo:[{
            Firstname: 'Skander',
            Lastname: 'TOUPRAN',
            Email: 'skander.t@gmail.com',
          }]
        },
      ];
      setJobApplicationData(data)
      if(props.JobPositionId){setFilteredApplications(
        data.filter(function(data){return data.JobpositionID == props.JobPositionId;})
      )}
  } 
}

async function writeCandidateData(candidatedata){
  if(props.test=="false"){
    let res = await candidateService.create(candidatedata)
    if(res.status==200){return res.data;}
  }
}

async function writeApplicationData(applicationdata){
  if(props.test=="false"){
      await applicationService.create(applicationdata);
      readApplicationDataWithCandidateInfo();
  }
}

async function updateApplicationData(applicationdata){
  if(props.test=="false"){
      await applicationService.update(applicationdata._id, applicationdata);
      readApplicationDataWithCandidateInfo();
  }
}

function AddCandidate(firstname, lastname, email){
  if(props.test=="true"){
    let newApplicationDataWithID = {
      _id: nextApplicationId,
      HrID:"",
      JobpositionID: props.JobPositionId,
      Assessmentscore: {
        status:'Notstarted',
        score:0,
        total:0,
        detail:{},
        comment:""
      },
      Assessmentstatus: '00_Added',
      Assessmenturl:'Ongoing',
      Decision:'Ongoing',
      CandidateID:'',
      CandidateInfo:[{
        Firstname: firstname,
        Lastname: lastname,
        Email: email,
      }]
    }
    setJobApplicationData([...jobApplicationData, newApplicationDataWithID]);
    setNextApplicationId(nextApplicationId + 1);
    setFilteredApplications([...filteredApplications, newApplicationDataWithID])

    updateJobPositionKPIsBasedOnApplicationModification(null, newApplicationDataWithID)
  }

  else {
    let newCandidateData = {
      Firstname: firstname,
      Lastname: lastname,
      Email: email,
      ApplicationsID: undefined,
      SubscriptionID: undefined,
      KpiID: undefined,
      FirebaseID: undefined,
    }
    writeCandidateData(newCandidateData)
    .then((newCandidateDataId)=>{
      console.log(newCandidateDataId)
      let newApplicationData = {
        CandidateID: newCandidateDataId,
        JobpositionID: props.JobPositionId,
        HrID:props.hrID,
        Assessmentstatus: '00_Added',
        Assessmenturl: 'Ongoing',
        Assessmentscore: {
          status:'Notstarted',
          score:0,
          total:0,
          detail:{},
          comment:""
        },
        Decision: 'Ongoing',
      };
      writeApplicationData(newApplicationData)
      updateJobPositionKPIsBasedOnApplicationModification(null, newApplicationData)
    })
  }
}

function ModifyCandidate(ApplicationWithCandidateInfo){
  let indexOfObjectToUpdate = jobApplicationData.findIndex(application => {return application._id == ApplicationWithCandidateInfo._id;})
  updateJobPositionKPIsBasedOnApplicationModification(jobApplicationData[indexOfObjectToUpdate], ApplicationWithCandidateInfo);

  if(props.test=="true"){
    let jobApplicationDataTemp = [...jobApplicationData];
    jobApplicationDataTemp[indexOfObjectToUpdate] = JSON.parse(JSON.stringify(ApplicationWithCandidateInfo));

    setJobApplicationData(jobApplicationDataTemp)
    if(props.JobPositionId){setFilteredApplications(
      jobApplicationDataTemp.filter(function(data){return data.JobpositionID == props.JobPositionId;})
    )}
  }
  else{
    let applicationdata = JSON.parse(JSON.stringify(ApplicationWithCandidateInfo));
    delete applicationdata.CandidateInfo;
    updateApplicationData(applicationdata);
  }
}



  // async function readApplicationDataWithCandidateInfoByMode(RoomMode){
  //   return new Promise((resolve, reject) => {
  //     roomService.findByRoomMode(RoomMode)
  //     .then((res) => {
  //         if(!res.data[0]){
  //           // reject();
  //           alert("No rooms found")
  //         }
  //         else{setRoomsData(res.data); resolve(res.data);}
  //     });
  //  });
    // roomService.findByRoomMode(RoomMode)
    // .then((res) => {
    //     if(!res.data[0]){
    //       console.log("false");return false; 
    //     }
    //     else{console.log(res.data);setRoomsData(res.data); return true;}
    // });
  //}
  // async function readApplicationDataWithCandidateInfoByJPID(){
  //   return new Promise((resolve, reject) => {
  //     applicationService.findAllByJPIdList(props.currentJPList)
  //     .then((res) => {
  //         if(!res.data[0]){
  //           reject(Error("No data"));
  //         }
  //         else{setRoomsData(res.data[0]); resolve(res.data[0]);}
  //     });
  //   })
  // }
  // async function writeData(_id, RoomID, data){
  //   return new Promise((resolve, reject) => {
  //     console.log(_id)
  //     console.log(data)
  //     roomService.update(_id, data).then(()=>{
  //       readApplicationDataWithCandidateInfoByID(RoomID).then((data)=>{resolve(data)}).catch(()=>{reject(Error("No data"));})
  //     })
  //   })
  // }
  // async function createData(RoomID, data){
  //   return new Promise((resolve, reject) => {
  //     roomService.create(data).then(()=>{console.log("datacreated")
  //       readApplicationDataWithCandidateInfoByID(data.RoomID).then(()=>{resolve("Data loaded");}).catch(()=>{reject(Error("No data"));})
  //     })
  //   })
  // }
  // async function removeData(RoomID){
  //   return new Promise((resolve, reject) => {
  //     roomService.remove(RoomID).then(()=>{
  //       resolve("Data loaded");})
  //     .catch(()=>{reject(Error("No data"));})
  //   })
  // }



    

    return (
      <div className="AppGrid">

        <Route path="/demo/workspace" >
          <AppTopBar type="roomsExplanation"/>
        </Route>
        <Route path="/workspace" exact>
          <AppTopBar type="roomsExplanation"/>
        </Route>
        <Route path="/demo/assess/:id" exact>
          {/* <AppTopBar type="candidateAssessmentExplanation"/> */}
            
        </Route>
        {/* <Route path="/workspace/studywme-browse">
          <AppTopBar type="studywmebrowseExplanation"/>
        </Route>
        <Route path="/workspace/studywme/">
          <AppQuote roomsdata={roomsdata}/>
        </Route>
        <Route path="/workspace/private/">
          <AppQuote roomsdata={roomsdata}/>
        </Route>
        <Route path="/workspace/liveroom-browse">
          <AppTopBar type="liveroombrowseExplanation"/>
        </Route>
        <Route path="/workspace/liveroom/">
          <AppQuote roomsdata={roomsdata}/>
        </Route> */}
        {/* containing : 
            Studywme : RoomID, NbUsers, Mode, AdminName, AdminPic, AdminActivity, AdminTimer, AdminTextPanel */}
          

        <div className="AppCentralPanel AppearingPanel">
          
          <Route path="/demo/workspace" >
            {/* <AppCamsMenu parentCallback={CamGridNavigation}/> */} 
            {props.JobPositionId=="" && <AppJobPositions jobpositions={JPData} JobPositionId={props.JobPositionId} handlePositionCreation={AddPosition} selectedPositionParentCallback={props.selectedPositionParentCallback} setShowAllJobPositions={setShowAllJobPositions} showAllJobPositions={showAllJobPositions}/>}
            {props.JobPositionId!="" && <AppCandidates applications={filteredApplications} JobPositionId={props.JobPositionId} handleApplicationCreation={AddCandidate} handleApplicationModification={ModifyCandidate} selectedPositionParentCallback={props.selectedPositionParentCallback}/>}
            {props.JobPositionId=="" && <div className="flex my-4"><AppKPI_1 jobpositions={JPData}/><AppKPI_2 jobpositions={JPData}/></div>}
          </Route>

          <Route path="/demo/kpis" >
            {props.JobPositionId=="" && <div className="flex my-4"><AppKPI_1 jobpositions={JPData}/><AppKPI_2 jobpositions={JPData}/></div>}
          </Route>

          <Route path="/workspace" exact>
            {props.JobPositionId=="" && <AppJobPositions jobpositions={JPData} JobPositionId={props.JobPositionId} handlePositionCreation={AddPosition} selectedPositionParentCallback={props.selectedPositionParentCallback} setShowAllJobPositions={setShowAllJobPositions} showAllJobPositions={showAllJobPositions}/>}
            {props.JobPositionId!="" && <AppCandidates applications={filteredApplications} JobPositionId={props.JobPositionId} handleApplicationCreation={AddCandidate} handleApplicationModification={ModifyCandidate} selectedPositionParentCallback={props.selectedPositionParentCallback}/>}
            {props.JobPositionId=="" && <div className="flex my-4"><AppKPI_1 jobpositions={JPData}/><AppKPI_2 jobpositions={JPData}/></div>}
          </Route>

          <Route path="/demo/assess/:id" exact>
              <AppVideoConference />
          </Route>
          
        </div>

        <Route path="/demo/workspace" >
            <AppJPPanel test="true" jobpositions={JPData} updateQuickAccessInJPData={updateJobPositionData} selectedPositionParentCallback={props.selectedPositionParentCallback} currentJPList={props.currentJPlist} updateCurrentJPListCallback={props.updateCurrentJPListCallback}/>
          </Route>
          <Route path="/workspace" >
            <AppJPPanel test="false" jobpositions={JPData} updateQuickAccessInJPData={updateJobPositionData} selectedPositionParentCallback={props.selectedPositionParentCallback} currentJPList={props.currentJPlist} updateCurrentJPListCallback={props.updateCurrentJPListCallback}/>
          </Route>
          <Route path="/demo/assess/:id" exact>
            <AppTimeline />
          </Route>

        {showErrorModal ? (
        <>
          <div
            className="fixed justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl text-black font-semibold">
                    Error
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-lg leading-relaxed">
                    ❎ Error unfortunately ! <br/><br/>

                    When you click on Close, you'll be redirected to our contact page to send us an email.<br/><br/>
                    
                    If you prefer, you can send us an email directly to :📮: <a style={{color:"blue"}}  href="mailto:contact@xkyre.com">contact@xkyre.com</a> <br/><br/>

                </p>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => window.location.href='/contact'}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

        {!JPData &&  !showErrorModal? (
                <div className="fixed w-full h-full flex justify-center items-center">
                <div
                    className="fixed justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-60 outline-none focus:outline-none"
                >
                    <div className="Modal relative w-auto mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                        <p className="mb-10 text-black text-lg leading-relaxed">
                        <br/> ⏳ We are querying our servers ... <br/>

                        </p>

                        </div>
                    </div>
                    </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
                </div>
            ) : null}


      </div>
    );
  };