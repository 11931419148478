import React, { useEffect } from "react";
import { useState } from "react";

export default function Mod_AddPositionToQuickAccess(props) {
  // const [showModal, setShowModal] = React.useState(props.showModal);
  let [jobPositionIDToAddToQuickAccess, setJobPositionIDToAddToQuickAccess] = useState();
  let [filteredNonQuickAccessJobPositions, setFilteredNonQuickAccessJobPositions] = useState([]);


  useEffect(()=>{
    if(props.jobpositions){
      let filteredTemp = props.jobpositions.filter(obj => {return obj.QuickAccess == "False";});
      setFilteredNonQuickAccessJobPositions(filteredTemp)
      if(filteredTemp.length>0){setJobPositionIDToAddToQuickAccess(filteredTemp[0]._id)}
    }
  },[props.jobpositions])

  return (
    // {/*// <>
    //  //   <button
    // //     className="bg-vorkv text-white active:bg-pink-600 font-bold uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
    // //     type="button"
    // //     onClick={() => setShowModal(true)}
    // //   >
    // //     Add Job Position
    // //   </button>
    //  {showModal ? ( */}
        <>
          <div
            className=" z-30 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 outline-none focus:outline-none z-50 "
          >
            <div className="Modal relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl font-semibold">
                    Add A Job Position To Quick Access
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {/* <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                    I always felt like I could do anything. That’s the main
                    thing people are controlled by! Thoughts- their perception
                    of themselves! They're slowed down by their perception of
                    themselves. If you're taught you can’t do anything, you
                    won’t do anything. I was taught I could do everything.
                  </p> */}


                {/* FORM */}
                
                {filteredNonQuickAccessJobPositions.length>0?

                  <form className="w-full max-w-lg">
                  <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-email">
                            Job Positions <br/><span className="text-xxs text-gray-500">(Filtered : Not already QuickAccess)</span>
                        </label>
                        <div className="relative">
                            <select onChange={event => setJobPositionIDToAddToQuickAccess(event.target.value)} className="block w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"> 
                            {filteredNonQuickAccessJobPositions.map(jobposition => {
                              return <option value={jobposition._id}>{jobposition.Jobpositiontitle}</option>
                            })}
                            </select>
                        </div>
                      </div>
                  </div>

                  </form>
                  :
                  <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-email">
                            You don't have any Job Position left to add. <br/><br/>

                            You need to add new job positions in the central panel first in order to pin them in the Quick Access section.
                        </label>
                      </div>
                  </div>
                }


                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>props.handleAddJobPositionToQuickAccess(null)}
                  >
                    {`${filteredNonQuickAccessJobPositions.length>0?`Cancel`:`Close`}`}
                  </button>
                  {filteredNonQuickAccessJobPositions.length>0&&<button
                    className="bg-vorkv text-white active:bg-emerald-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>props.handleAddJobPositionToQuickAccess(jobPositionIDToAddToQuickAccess)}
                  >
                    Add Position
                  </button>}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    //   ) : <></>}
    // </>
  );
}