import React from "react";
import emailjs from '@emailjs/browser';

export default function Mod_BeABetaTester(props) {
  const [showModal, setShowModal] = React.useState(props.ShowByDefault || false);
  const [showLoadingModal, setShowLoadingModal] = React.useState(false);
  const [showThanksModal, setShowThanksModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  let [firstname, setFirstName] = React.useState();
  let [lastname, setLastName] = React.useState();
  let [email, setEmail] = React.useState();


  function CheckIfInfoCompleteAndSubmit(){
    if(document.getElementById("grid-first-name").value.length != 0 && document.getElementById("grid-last-name").value.length != 0 && document.getElementById("grid-email").value.length != 0){
      setShowLoadingModal(true);
      SubmitBetaTesterInfo();
    }
    else{
      alert("Please fill all information")
    }
  }

  function SubmitBetaTesterInfo(){
    let emailParams = {
      first_name: firstname,
      last_name:lastname,
      email:email,
    };

    emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_BETATESTER_ID, emailParams, process.env.REACT_APP_EMAILJS_USER_ID)
      .then((result) => {
        setShowModal(false);
        setShowLoadingModal(false);
        setShowThanksModal(true);
        // console.log(result.text);
      }, (error) => {
        setShowModal(false);
        setShowLoadingModal(false);
        setShowErrorModal(true);
          // console.log(error.text);
      });
  }

  return (
    <>
      {props.InvisibleButton || false ?  <></>: <button
        className={`${props.white?"bg-white text-vorkv":"bg-vorkv text-white"} active:bg-pink-600 font-bold text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
        type="button"
        onClick={() => setShowModal(true)}
      >
        Be A Beta Tester
      </button>}

      {showModal ? (
        <>
          <div
            className="fixed justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl text-black font-semibold">
                    End Of Project
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-sm leading-relaxed">
                    {/* 🍬 Join our Beta Tester Community to have your own test account and more !!<br/><br/>
                    We are still setting up our process to increase the efficiency in which we handle the feedback we receive.<br/>
                    It's important to inform you regularly of the impact you have.<br/>
                    You'll receive a mail explaining the next steps 😃 */}

                    🍬 The project is now closed ! 🍬 <br/><br/>
                    We wanted to thank you for offering your knowledge and excitement during trying times. We appreciate your willingness to work with us, and we’re grateful for the time you have devoted.<br/><br/>
                    We plan to leave the website and small demo live for our team's use. <br/><br/>
                    You can still contact us at: <a className="text-blue-400" href = "mailto:contact@xkyre.com">contact@xkyre.com</a> for any questions. <br/>
                    Please let us know if you have any other comments or concerns as well!<br/>
                  </p>


                {/* FORM */}

                {/* <form className="w-full" >
                    <div className="flex flex-wrap mx-6 mb-6">
                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-first-name">
                            First Name
                        </label>
                        <input onChange={event => setFirstName(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" type="text" placeholder="Jane"/>
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-last-name">
                            Last Name
                        </label>
                        <input onChange={event => setLastName(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Doe"/>
                        </div>
                    </div>
                    <div className="flex flex-wrap mx-6 mb-6">
                        <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xxs font-bold mb-2" for="grid-email">
                            e-mail
                        </label>
                        <input onChange={event => setEmail(event.target.value)} className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-email" type="text" placeholder="janedoe@gmail.com"/>
                        </div>
                    </div>

                </form> */}

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    CLOSE
                  </button>
                  {/* <button
                    className="bg-vorkv text-white active:bg-emerald-600 font-bold uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={CheckIfInfoCompleteAndSubmit}
                  >
                    Join
                  </button> */}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

{showThanksModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl text-black font-semibold">
                    Beta Tester Community
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-sm leading-relaxed">
                    🍬 Thank you for joining ! <br/><br/>

                    You may use the following link to register as a user and start testing the product 🥸 
                    <br/> 🔗 <a style={{color:"blue"}}  target="_blank" href=" https://xkyre.com/auth/signup"> https://xkyre.com/auth/signup</a> ✅ <br/><br/>
                  
                    We will add you to the Beta Tester list and you'll receive an email later today 📮
                  </p>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowThanksModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}


{showErrorModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl text-black font-semibold">
                    Beta Tester Community
                  </h3>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-sm leading-relaxed">
                    ❎ Error unfortunately ! <br/><br/>

                    Please try again later or send us an email to be added to the Beta Tester list <br/>:📮: <a style={{color:"blue"}}  href="mailto:contact@xkyre.com">contact@xkyre.com</a> <br/><br/>

                    You may use the following link to register as a user and start testing the product 🥸 
                    <br/> 🔗 <a style={{color:"blue"}}  target="_blank" href=" https://xkyre.com/auth/signup"> https://xkyre.com/auth/signup</a> ✅ <br/><br/>
                  </p>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowErrorModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}



{showLoadingModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-60 outline-none focus:outline-none"
          >
            <div className="Modal relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="mb-10 text-black text-sm leading-relaxed">
                  <br/> ⏳ We are querying our servers ... <br/>

                  </p>

                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
        </>
      ) : null}




    </>
  );
}