import svg_title from "../../../Assets/Images/activities/titleclipboard.svg"
import svg_timespent from "../../../Assets/Images/activities/checkcircle.svg"
import svg_timetoachieve from "../../../Assets/Images/activities/flag.svg"
import svg_modify from "../../../Assets/Images/activities/modify.svg"
import svg_delete from "../../../Assets/Images/activities/delete.svg"

import Mod_AddPosition from "../../../Modals/Mod_AddPosition"

export default function Activity (props) {
    function handleActivityCreation(){
        props.parentCallback("NewActivity", props.value)
    }
    function handleActivityRenaming(){
        props.parentCallback("Renaming", props.value)
    }
    function handleGoalModification(){
        props.parentCallback("Goal", props.value)
    }
    function handleActivityDelete(){
        props.parentCallback("Delete", props.value)
    }
    
    

    return(
        // Timespent = -1 means no activity / activity has been deleted

        
            <div className="activityWithBtn" id={"activity"+props.value}>
                {props.timespent<0 ? 
                <button className="activity emptyactivity" onClick={handleActivityCreation}>
                    <input type="radio" name="emptyact" value={props.value} />
                    <div className="txt">Bookmark a position</div>
                    {/* <Mod_AddPosition /> */}
                </button>
                :
                <label className="activity filledactivity" >  
                    <input type="radio" name="act" value={props.value} />
                    <div className="title">
                        <div className="img"><img src={svg_title}/></div>
                        <div className="txt">{props.activity}</div>
                    </div>
                    <div className="timespent">
                        <div className="img"><img src={svg_timespent}/></div>
                        <div className="txt">{props.timespent}</div>
                    </div>
                    <div className="timetoachieve">
                        <div className="img"><img src={svg_timetoachieve}/></div>
                        <div className="txt">{props.timegoal}</div>
                    </div>
                </label>
                }
                {props.timespent<0 ? <div></div> :<div className="activitymodification">
                    {/* For the selected activity :<br/> */}
                    {/* <button className="renameactivity" onClick={handleActivityRenaming}><img src={svg_modify}/>Name</button>
                    <button className="modifygoal" onClick={handleGoalModification}><img src={svg_modify}/>Goal</button> */}
                    <button className="modifygoal" onClick={handleActivityDelete}><img src={svg_delete}/> Remove</button>
                </div> 
                }
            </div>
    );
}
