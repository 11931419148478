import React, { useEffect } from "react";
import { useState } from "react";
import SwitchCheck from "../Components/Utils/SwitchCheck";

import ResponsiveVideoEmbed from '../Components/TailwindTreact/helpers/ResponsiveVideoEmbed';
import styled from "styled-components";
import tw from "twin.macro";
const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black`}
  }
`;

function SingleSkillAssessment(props){
  let [isTheSkillAssessed, setIsTheSkillAssessed] = useState(props.assessmentOfTheSkill!="Not Assessed");
  let [skillAssessmentReview, setskillAssessmentReview] = useState(props.assessmentOfTheSkill=="Not Assessed"?"Good":props.assessmentOfTheSkill);



  function handleChangeIsTheSkillAssessed(e){
    setIsTheSkillAssessed(e.target.checked);
    if(e.target.checked){
      props.parentCallback("IsAssessed", props.skill, "Good")
      setskillAssessmentReview("Good")
    } 
    else{
      props.parentCallback("IsNotAssessed", props.skill, skillAssessmentReview);
      setskillAssessmentReview("Not Assessed")
    }
  }

  function handleSkillAssessmentReviewChange(newReview){
    // let newReview = isTheSkillAssessmentPositive?false:true;
    setskillAssessmentReview(newReview)
    props.parentCallback("IsReviewed", props.skill, newReview)
  }


  return (
    <div className="flex flex-wrap w-full -mx-3 mb-6">
      <div className="w-full px-3">
                        
        

          {isTheSkillAssessed?
          <div className="flex w-full align-center mb-2  h-6">
            <input onClick={handleChangeIsTheSkillAssessed} className="mt-1" type="checkbox" id="isTheSkillAssessed" name="isTheSkillAssessed" checked/>
            <div className="flex justify-between w-full ml-3">
              <label className="block mt-1 uppercase tracking-wide text-gray-700 text-xxs" for="grid-first-name">
                  {props.skill}
              </label>
              {/* <SwitchCheck isToggleTrue={isTheSkillAssessmentPositive} handleToggle={handleSkillAssessmentReviewChange} id={props.skill} leftChoice="No" rightChoice="Yes" image={false}/> */}
                <div className="flex ">  
                  {(() => {
                    switch (skillAssessmentReview) {
                      case 'Poor':
                        return <div className="mt-1 mr-1 bg-red-300 rounded-full h-4 w-4 text-xxs text-center">1</div> 
                      case 'Fair':
                        return <div className="mt-1 mr-1 bg-red-100 rounded-full h-4 w-4 text-xxs text-center">2</div> 
                      case 'Good':
                        return <div className="mt-1 mr-1 bg-teal-100 rounded-full h-4 w-4 text-xxs text-center">3</div> 
                      case 'Very Good':
                        return <div className="mt-1 mr-1 bg-green-200 rounded-full h-4 w-4 text-xxs text-center">4</div> 
                      case 'Excellent':
                        return <div className="mt-1 mr-1 bg-green-300 rounded-full h-4 w-4 text-xxs text-center">5</div> 
                      default:
                        return null
                    }
                  })()}
                  <select onChange={event => handleSkillAssessmentReviewChange(event.target.value)} value={skillAssessmentReview} id={props.skill} className="block w-24 bg-gray-100  border border-gray-200 text-gray-700 py-1 px-2 text-center rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"> 
                        <option value="Poor">Poor</option>
                        <option value="Fair">Fair</option>
                        <option value="Good">Good</option>
                        <option value="Very Good">Very Good</option>
                        <option value="Excellent">Excellent</option>
                  </select>
                </div>
              </div>
          </div>
          :
          <div className="flex w-full align-center mb-2 h-6">
            <input onClick={handleChangeIsTheSkillAssessed}  className="mt-1" type="checkbox" id="isTheSkillAssessed" name="isTheSkillAssessed"/>
            <div className="flex justify-between w-full ml-3">
              <label className="block mt-1  uppercase tracking-wide text-gray-700 text-xxs" for="grid-first-name">
                  {props.skill}
              </label>
              {/* <SwitchCheck isToggleTrue={true} id={props.skill} leftChoice="No" rightChoice="Yes" image={false}/> */}
            </div>
          </div>
          }

        

        <p className="inline-block break-normal w-10/12 whitespace-pre-line tracking-wide text-gray-700 text-xs mb-2">{props.explanation}</p>
      
      </div>
    </div>
  )
}

export default function Mod_AssessCandidate(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteWarningModal, setShowDeleteWarningModal] = React.useState(false);
  const [decision, setDecision] = React.useState(props.application.Decision);
  // const [skillsDetail, setSkillsDetail] = React.useState(props.application.Assessmentscore.detail);
  const [application, setApplication] = React.useState(JSON.parse(JSON.stringify(props.application)));
  // const [score, setScore] = React.useState(props.score)


  function checkIfAGivenSkillIsAssessedAndReturnValue(nameoftheskill){
    if(!application.Assessmentscore.detail.hasOwnProperty(nameoftheskill)){return "Not Assessed"}
    else {return application.Assessmentscore.detail[nameoftheskill]}
  }

  function handleDecisionChange(newDecision){
    setDecision(newDecision)

    let tempApplication = application
    tempApplication.Decision=newDecision;
    setApplication(tempApplication)
  }
  
  function handleSingleSkillAssessment(isAssessedOrReviewed, nameoftheskill, value){
    let tempApplication = application

    let newScoreForThisSkillToAddOrRemove;
    switch(value){
      case "Poor": newScoreForThisSkillToAddOrRemove=1; break;
      case "Fair": newScoreForThisSkillToAddOrRemove=2; break;
      case "Good": newScoreForThisSkillToAddOrRemove=3; break;
      case "Very Good": newScoreForThisSkillToAddOrRemove=4; break;
      case "Excellent": newScoreForThisSkillToAddOrRemove=5; break;
    }

    let previousScoreForThisSkill=0;
    if(tempApplication.Assessmentscore.detail.hasOwnProperty(nameoftheskill)){
      switch(tempApplication.Assessmentscore.detail[nameoftheskill]){
        case "Poor": previousScoreForThisSkill=1; break;
        case "Fair": previousScoreForThisSkill=2; break;
        case "Good": previousScoreForThisSkill=3; break;
        case "Very Good": previousScoreForThisSkill=4; break;
        case "Excellent": previousScoreForThisSkill=5; break;
      }
    }

    if(isAssessedOrReviewed == "IsNotAssessed"){
      delete tempApplication.Assessmentscore.detail[nameoftheskill]
      tempApplication.Assessmentscore.score=tempApplication.Assessmentscore.score-newScoreForThisSkillToAddOrRemove
      tempApplication.Assessmentscore.total=tempApplication.Assessmentscore.total-5
    }
    else{
      if(!tempApplication.Assessmentscore.detail){tempApplication.Assessmentscore.detail={}}
      tempApplication.Assessmentscore.detail[nameoftheskill] = value
      tempApplication.Assessmentscore.score=tempApplication.Assessmentscore.score+newScoreForThisSkillToAddOrRemove-previousScoreForThisSkill
      if(isAssessedOrReviewed=="IsAssessed"){tempApplication.Assessmentscore.total=tempApplication.Assessmentscore.total+5}
    }
    setApplication(tempApplication)
  }

  function handleCommentChange(newComment){
    let tempApplication = application
    tempApplication.Assessmentscore.comment = newComment
    setApplication(tempApplication)
  }


  function deleteReview(){
    
      let tempApplication = application
      tempApplication.Assessmentstatus = "02_Assessed"
      tempApplication.Assessmentscore.status = "Ongoing"
      tempApplication.Assessmentscore.score=0
      tempApplication.Assessmentscore.total=0
      tempApplication.Assessmentscore.detail={};
      tempApplication.Assessmentscore.comment="";
      tempApplication.Decision="Ongoing"
      setApplication(tempApplication);
      props.handleAssessmentModification(tempApplication);
      setShowModal(false)
      setShowDeleteWarningModal(false)
    
  }

  function saveAssessment(){
    let tempApplication = application
    tempApplication.Assessmentscore.status = "Done"
    if(decision=="Ongoing"){tempApplication.Assessmentstatus = "03_Reviewed"}
    else if(decision=="Approved"){tempApplication.Assessmentstatus = "04_Approved"}
    else{tempApplication.Assessmentstatus = "05_Rejected"}

    setApplication(tempApplication)

    props.handleAssessmentModification(tempApplication);
    setShowModal(false)
  }

  function cancelAssessment(){
    setApplication(JSON.parse(JSON.stringify(props.application)));
    setShowModal(false)
  }

  return (
    <>
      <button
        className="bg-white text-black active:bg-pink-600  uppercase text-xxs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Review
      </button>
      {showModal ? (
        <>
          <div
            className="  justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none"
          >
            
            <div className="Modal relative w-auto my-6 mx-auto max-w-5xl ">
              {/*content*/}
              <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-xl ">
                    Review Assessment : {props.Candidate}
                  </h3>

                  
                </div>
                {/*body*/}

                <div className="flex w-full">
                  <div className="relative w-2/3 p-6 my-4">
                      <StyledResponsiveVideoEmbed
                      // 374265101
                        url={application.Assessmenturl}
                        background="transparent"
                      />
                    <div className="relative">
                      <div className="mt-6 mb-6 text-gray-500 text-xs leading-relaxed w-full whitespace-pre-line">
                        Using the review on your right, would you hire this candidate ? 
                      </div>
            
                      <div className="relative flex w-full justify-center">
                          {/* <SwitchCheck isToggleTrue={application.Decision=="Approved"} handleToggle={handleDecisionChange} id={props.id} leftChoice={props.leftChoice} rightChoice={props.rightChoice} image={props.image}/> */}
                          {decision=="Ongoing"?<img className="rounded-full mx-1" src={props.noChoice} width="25" height="25" alt="Decision" />:<img className="rounded-full mx-1" src={decision=="Approved"?props.rightChoice:props.leftChoice} width="25" height="25" alt="Decision" />}
                          <select onChange={event => handleDecisionChange(event.target.value)} value={decision} className="block w-24 bg-gray-100 border border-gray-200 text-gray-700 py-3 px-4 text-center rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"> 
                                {/* {decision=="Approved" ? <option value="Approved" selected>Approved</option>:<option value="Approved">Approved</option>}
                                {decision=="Rejected" ? <option value="Rejected" selected>Rejected</option>:<option value="Rejected">Rejected</option>}
                                {decision=="Ongoing" ? <option value="Ongoing" selected>Ongoing</option>:<option value="Ongoing">Ongoing</option>} */}
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Ongoing">Ongoing</option>
                          </select>
                      
                      </div>

                      {/* <SwitchCheck isToggleTrue={application.Decision=='Approved'?true:false} id={application.id} leftChoice={img_rejected} rightChoice={img_accepted} image={true}/> */}
                      


                      <div className="flex flex-wrap -mx-3">
                            <div className="w-full px-3">
                            <p className="my-4 text-gray-500 text-xs leading-relaxed w-full whitespace-pre-line">
                              Write a comment to remind you of the reason you rejected or approved the candidate
                            </p>
                            <label className="block uppercase tracking-wide text-gray-700 text-xxs  mb-2" for="grid-job-position">
                              Comment
                            </label>
                            <textarea onChange={event => handleCommentChange(event.target.value)} className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-job-position" type="text" placeholder="" defaultValue={application.Assessmentscore.comment}/>
                            {/* <p class="text-gray-600 text-xs italic">Make it as long and as crazy as you'd like</p> */}
                            </div>
                        </div>
                    </div>

                  </div>

                  <div className="relative p-6 flex-auto w-1/2 h-2/5 overflow-y-scroll">
                    <p className="my-4 text-gray-500 text-xs leading-relaxed w-full whitespace-pre-line">
                      The review questions help you decide and remind you of the reason you made the decision to accept or reject a candidate <br/><br/>
                      Check the skills assessed in the video, afterwards you can select a level depending on the candidate's ability to demonstrate the skills
                    </p>


                    {/* FORM */}
                    {application.Assessmentscore.detail?
                    <form className="w-auto h-2/5 overflow-y-scroll">

                        <SingleSkillAssessment assessmentOfTheSkill={checkIfAGivenSkillIsAssessedAndReturnValue("Diplomacy")} parentCallback={handleSingleSkillAssessment} skill="Diplomacy" explanation="Has the candidate been able to express his point of view and disagreement the right way"/>
                        <SingleSkillAssessment assessmentOfTheSkill={checkIfAGivenSkillIsAssessedAndReturnValue("Decision Making")} parentCallback={handleSingleSkillAssessment} skill="Decision Making" explanation="Has the candidate been able to take decisions in the given context"/>
                        <SingleSkillAssessment assessmentOfTheSkill={checkIfAGivenSkillIsAssessedAndReturnValue("Handling a client")} parentCallback={handleSingleSkillAssessment} skill="Handling a client" explanation="Has the candidate been able to reassure the customer"/>
                        <SingleSkillAssessment assessmentOfTheSkill={checkIfAGivenSkillIsAssessedAndReturnValue("Finding Solutions")} parentCallback={handleSingleSkillAssessment} skill="Finding Solutions" explanation="Has the candidate been able to come up with solutions to handle the problem at hand"/>
                        <SingleSkillAssessment assessmentOfTheSkill={checkIfAGivenSkillIsAssessedAndReturnValue("Facilitating")} parentCallback={handleSingleSkillAssessment} skill="Facilitating" explanation="Has the candidate been able to keep the meeting focused on the main subject, and allow a time for each person to express their point of view"/>
                        
                    </form>
                    :

                    <form className="w-auto h-2/5 overflow-y-scroll">

                        <SingleSkillAssessment assessmentOfTheSkill={"Not Assessed"} parentCallback={handleSingleSkillAssessment} skill="Diplomacy" explanation="Has the candidate been able to express his point of view and disagreement the right way"/>
                        <SingleSkillAssessment assessmentOfTheSkill={"Not Assessed"} parentCallback={handleSingleSkillAssessment} skill="Decision Making" explanation="Has the candidate been able to take decisions in the given context"/>
                        <SingleSkillAssessment assessmentOfTheSkill={"Not Assessed"} parentCallback={handleSingleSkillAssessment} skill="Handling a client" explanation="Has the candidate been able to reassure the customer"/>
                        <SingleSkillAssessment assessmentOfTheSkill={"Not Assessed"} parentCallback={handleSingleSkillAssessment} skill="Finding Solutions" explanation="Has the candidate been able to come up with solutions to handle the problem at hand"/>
                        <SingleSkillAssessment assessmentOfTheSkill={"Not Assessed"} parentCallback={handleSingleSkillAssessment} skill="Facilitating" explanation="Has the candidate been able to keep the meeting focused on the main subject, and allow a time for each person to express their point of view"/>
                        
                    </form>
                    }
                  </div>

                
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="bg-red-400 text-white active:bg-emerald-600  uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={()=>setShowDeleteWarningModal(true)}
                  >
                    Delete Review
                  </button>
                  <div className="flex items-center justify-end">
                      <button
                        className="text-red-500 background-transparent  uppercase px-6 py-2 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={cancelAssessment}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-vorkv text-white active:bg-emerald-600  uppercase text-xs px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={saveAssessment}
                      >
                        Save Review
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}

      {showDeleteWarningModal ? (
        <>
          <div
            className="absolute justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-60 outline-none focus:outline-none"
          >
            <div className="Modal border relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="inline-block break-normal w-10/12 whitespace-pre-line tracking-wide text-gray-700 text-s mb-2">
                  <br/> <p className="text-red-500 text-sm">You are about to permanently delete the Review</p> <br/><br/>

                  The candidate's information and video will still be available to review the candidate again.<br/>
                  The current Assessment Score, Decision, Comment and Checked Skills will be permanently erased from our databases.<br/><br/>
                  
                  Are you sure you want to permanently delete the existing review ?<br/>

                  </p>

                  <div className="flex justify-end">
                    <button
                      className="bg-white text-gray-700 uppercase text-s px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowDeleteWarningModal(false)}
                    >
                      No
                    </button>

                    <button
                      className="bg-white text-gray-700  uppercase text-s px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => deleteReview()}
                    >
                      Yes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-50 bg-black"></div>
        </>
      ) : null}


    </>
  );
}