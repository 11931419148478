import React, { useState, useEffect } from 'react';
// import ProgressComponent from '@material-ui/core/CircularProgress';
import { useParams } from "react-router";

function AppVideoConference() {
  const {id: roomId} = useParams();
  const [loading, setLoading] = useState(true);
  const containerStyle = {
    width: '100%',
    height: '100%',
  };


  const jitsiContainerStyle = {
    display: (loading ? 'none' : 'block'),
    width: '100%',
    height: '100%',
  }

 function startConference() {
  try {
   const domain = 'assess.xkyre.com';
   const options = {
    roomName: roomId,
    height: '100%',
    width: '100%',
    parentNode: document.getElementById('jitsi-container'),
    interfaceConfigOverwrite: {
     filmStripOnly: false,
     SHOW_JITSI_WATERMARK: false,
    },
    configOverwrite: {
     disableSimulcast: false,
    },
   };

   const api = new window.JitsiMeetExternalAPI(domain, options);

   setLoading(false);
   api.addEventListener('videoConferenceJoined', () => {
    console.log('Local User Joined');
    api.executeCommand('displayName', 'MyName');
   });
  } catch (error) {
   console.error('Failed to load Jitsi API', error);
  }
 }

 useEffect(() => {
  // verify the JitsiMeetExternalAPI constructor is added to the global..
  if (window.JitsiMeetExternalAPI) startConference();
  else alert('Jitsi Meet API script not loaded');
 }, []);

 return (
  <div
   style={containerStyle}
  >
   {loading && <div>Loading ...</div>}
   <div
    id="jitsi-container"
    style={jitsiContainerStyle}
   />
  </div>
 );
}

export default AppVideoConference;