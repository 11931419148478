import axios from "axios";
const APISERVER = process.env.REACT_APP_BACKEND_API_HOST;
const http = axios.create({
    //baseURL: `${APISERVER}/api/${USER}`,
    baseURL: `${APISERVER}`,
    headers: {
      "Content-type": "application/json"
    }
});

const create = data => {
    return http.post("/api/room", data);
};

const getAll = () => {
  return http.get("/api/room");
};
  
const get = id => {
return http.get(`/api/room/${id}`);
};

const update = (id, data) => {
return http.post(`/api/room/${id}`, data);
};

const remove = id => {
return http.delete(`/api/room?uid=${id}`);
};

const removeAll = () => {
return http.delete(`/api/room`);
};

const findByRoomID = roomid => {
  return http.get(`/api/room?uid=${roomid}`);
};

const findByRoomMode = roommode => {
  return http.get(`/api/room-mode?mode=${roommode}`);
};

export default {
getAll,
get,
create,
update,
remove,
removeAll,
findByRoomID,
findByRoomMode
};