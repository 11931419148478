import AppTopBarImg from "../../Assets/Images/AppTopBar/info.svg"

{/* containing : 
Studywme : RoomID, NbUsers, Mode, AdminName, AdminPic, AdminActivity, AdminTimer, AdminTextPanel */}
        
export default function AppTopBar(props){
    let roomsExplanation = "Welcome to your workspace, the steps to assess candidates are : \n - [Left Panel] Head to the marketplace to add Role-Play Scenarios for your new job positions\n - [Right Panel] Add a job position (Dev, HR, PM, ...) with a type of RolePlay to assess your candidates \n - Select a job position and Add the Candidates' information \n - Once the candidate complete the Role Play Assessment you'll receive the video that you may share with the team \n - You may then review your candidate performance with our assessment quiz and make a decision "
  //let roomsExplanation = "Welcome to your workspace, You can choose to : \n - Create (or Join) a private room and share the link with your friends for team sessions\n - Join a Liveroom with random people working, a bit like a library \n - Find someone to work with on a Studywithme channel OR Live your own channel by creating one \n - On the right panel, you may manage your activities and start the timer to work along or on your own"
    // let candidateAssessmentExplanation = "Welcome to your workspace, the steps to assess candidates are : \n - [Left Panel] Head to the marketplace to add Role-Play Scenarios for your new job positions\n - [Right Panel] Add a job position (Dev, HR, PM, ...) with a type of RolePlay to assess your candidates \n - Select a job position and Add the Candidates' information \n - Once the candidate complete the Role Play Assessment you'll receive the video that you may share with the team \n - You may then review your candidate performance with our assessment quiz and make a decision "
    let candidateAssessmentExplanation = " ... "
    let liveroombrowseExplanation = "The Liveroom mode is where random people work together to share their motivation  \n Connect to a channel and follow the pace or just watch the others working to find motivation\n You'll find below a list with information to make your choice"
    
    return(
        <div className="AppTopBar">
            <img className="img" src={AppTopBarImg} />
            <div className="txt">
                {/* {console.log(props.type)} */}
                
                {props.type == "roomsExplanation" ? roomsExplanation.split('\n').map(line=><p>{line}</p>) : 
                props.type == "candidateAssessmentExplanation" ? candidateAssessmentExplanation.split('\n').map(line=><p>{line}</p>) : 
                liveroombrowseExplanation.split('\n').map(line=><p>{line}</p>)}
                
                {/* “The best time to plant a tree was 20 years ago. The second best time is now.” – Chinese Proverb */}
                {/* Test to see if the Data is good */}
                {/* AdminFirebaseId:    {props.roomsdata.AdminFirebaseId},<br/>
                Users:              {props.roomsdata.Users},<br/> */}

                
            </div>
        </div>
    )
}
