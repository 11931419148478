import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../TailwindTreact/components/headers/light.js";
import Mod_BeABetaTester from "../../Modals/Mod_BeABetaTester"

import { Link } from "react-router-dom";

const StyledHeaderGray = styled(Header)`
${tw`pt-8 max-w-none text-white`}
${DesktopNavLinks} ${NavLink}, ${LogoLink} {
  ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
}
${NavToggle}.closed {
  ${tw`text-gray-100 hover:text-vorkv`}
}
`;

const StyledHeaderBlack = styled(Header)`
${tw`pt-8 max-w-none text-black hocus:text-black`}
${DesktopNavLinks} ${NavLink}, ${LogoLink} {
  ${tw`text-black hover:border-black hover:text-black`}
}
${NavToggle}.closed {
  ${tw`text-black hover:text-vorkv`}
}
`;

export default (props) => {
    const navLinks = [
      <NavLinks key={1} >
        <Link to="/home/">
          <NavLink href="#">  Home </NavLink>
        </Link>
        {/* <Link to="/"> */}
          <NavLink href="#" className="text-gray-400">  About Us </NavLink>
        {/* </Link> */}
        {/* <Link to="/"> */}
          <NavLink href="#" className="text-gray-400">  Pricing </NavLink>
        {/* </Link> */}
        <Link to="/contact/">
          <NavLink href="#">  Contact Us </NavLink>
        </Link>
      </NavLinks>//,
      // <NavLinks key={2}>
        /* <PrimaryLink href="/#">
          Be a Beta Tester
        </PrimaryLink> */
        /* <Mod_BeABetaTester /> */
      // </NavLinks>
    ];
  
    return (
        props.color == "gray" ? <StyledHeaderGray links={navLinks} color="white"/> :  <StyledHeaderBlack links={navLinks} color="blue"/>
    );
  };
  