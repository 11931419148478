// import { Switch, Redirect } from 'react-router';
// import { Route } from 'react-router-dom';
// import { useEffect } from 'react';
// -
import './Landing.css';
// import {TW_Footer} from '../Components/TailwindComponents/TW_Footer';

//TREACT
import AnimationRevealPage from "../Components/TailwindTreact/helpers/AnimationRevealPage.js";
import Hero2 from "../Components/TailwindTreact/components/hero/BackgroundAsImage";
import FeatureStats from "../Components/TailwindTreact/components/features/ThreeColCenteredStatsPrimaryBackground.js";

import Feature9 from "../Components/TailwindTreact/components/features/TwoColWithTwoFeaturesAndButtons.js";
import Feature11 from "../Components/TailwindTreact/components/features/VerticalWithAlternateImageAndText.js";


import Card5 from "../Components/TailwindTreact/components/cards/ThreeColSlider";
import FAQ from "../Components/TailwindTreact/components/faqs/SingleCol.js";
import GetStarted from "../Components/TailwindTreact/components/cta/GetStartedLight.js";
import Footer from "../Components/TailwindTreact/components/footers/SimpleFiveColumn";
import tw from "twin.macro";
import Mod_BeABetaTester from '../Modals/Mod_BeABetaTester';
const HighlightedText = tw.span`text-vorkv`;



export default function Landing(props){

    

    

    // function NavBarCallback(menuChoice){
    //     props.parentCallback(menuChoice);
    // }

    return(
        <div id="Landing">
            <AnimationRevealPage>
            <Mod_BeABetaTester ShowByDefault={true} InvisibleButton={true}/>
            {/* <Hero /> */}
            <Hero2 />
            {/* <Hero3 />
            <Hero4 />
            <Hero5 />
            <Hero6 />
            <Hero7 /> */}

            <Card5/>
            <GetStarted/>

            <Feature9 />
            <Feature11 /> 
            <FeatureStats/>
            {/* <Features 
                heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
            /> */}
            {/* <MainFeature
                heading={<>Cloud built by and for <HighlightedText>Professionals</HighlightedText></>}
            /> */}
            {/* <Testimonial 
                heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
            /> */}
            {/* <Feature8 />  personnages */}
            
            {/* <Mobile/> */}
            {/* <Feature7 /> screen */}
            {/* <Feature3 /> Steps to join */}
            {/* <Feature6 /> KPIs */}
            {/* <JoinTest/> */}
            {/* <Feature1 /> 
            <Feature2 />
            <Feature4 />
            <Feature5 /> */}
            {/* <Feature10 /> */}
            

            {/* <FAQ
                heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
            /> */}
            
            <Footer />
            </AnimationRevealPage>
            {/* <button onClick={handleLogin}>Log in</button>
            <button onClick={handleSignIn}>Sign in</button> */}
        </div>
    )
}