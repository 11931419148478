import axios from "axios";
const APISERVER = process.env.REACT_APP_BACKEND_API_HOST;
const http = axios.create({
    //baseURL: `${APISERVER}/api/${USER}`,
    baseURL: `${APISERVER}`,
    headers: {
      "Content-type": "application/json"
    }
});

const create = data => {
    return http.post("/api/hr", data);
};

const getAll = () => {
    return http.get("/api/hr");
  };
  
const get = id => {
return http.get(`/api/hr/${id}`);
};

const update = (id, data) => {
return http.post(`/api/hr/${id}`, data);
};

const remove = id => {
return http.delete(`/api/hr/${id}`);
};

const removeAll = () => {
return http.delete(`/api/hr`);
};

const findByFirebaseId = firebaseid => {
return http.get(`/api/hr?uid=${firebaseid}`);
};

export default {
getAll,
get,
create,
update,
remove,
removeAll,
findByFirebaseId
};