import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth, signInWithRedirect, getRedirectResult, signInWithPopup, signOut } from "firebase/auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

// require('firebase/auth')
/*GOOGLE AUTHENTICATION*/
async function SignWithGoogle(){
    signOut(getAuth());//Disconnect if there is a user
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    auth.languageCode = 'en';
    signInWithRedirect(auth, provider);
}

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

let instance;

async function connectFirebase() {
    if (typeof window !== "undefined") {
        if (instance) return instance
        instance = initializeApp(firebaseConfig)//firebase.initializeApp(firebaseConfig);
        // console.log("instance connected")
        return instance
    }
    return null
}

async function createUser(email, password){
  return new Promise((resolve, reject) => {
        if(instance){
            const auth =  getAuth(instance)
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in 
              resolve(userCredential);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              reject(errorMessage)
              // console.log(errorCode)
              // console.log(errorMessage)
              // ..
            });
        }
  })
}

async function signUser(email, password){
    return new Promise((resolve, reject) => {
        if (instance) {
            // console.log(instance)
            const auth = getAuth(instance)
            // console.log(auth)
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
              // Signed in 
              console.log(userCredential)
              resolve(userCredential) //userCredential.user.uid
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              reject(errorMessage)
              // console.log(errorCode)
              // console.log(errorMessage)
            });
        }
    });
}

async function logoutUser(){
    try {
        if (instance) {
          localStorage.clear();
          indexedDB.deleteDatabase("firebaseLocalStorageDb")
        
            // await instance.auth().signOut();
            signOut(getAuth(instance));
            signOut(getAuth());
        }
      } catch (error) {
        console.log("error", error);
      }
}

export default {
    connectFirebase,
    createUser,
    signUser,
    logoutUser,
    SignWithGoogle,
    getRedirectResult,
    signInWithPopup,
    signInWithRedirect,
    getAuth,
}