import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "../components/headers/light.js";
import Footer from "../components/footers/SimpleFiveColumn";
import MainFeature1 from "../components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "../components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "../components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "../images/support-icon.svg";
import ShieldIconImage from "../images/shield-icon.svg";
import CustomerLoveIconImage from "../images/simple-icon.svg";
import MissionImage from "../images/robert-stump-bwpgwJesFhw-unsplash.jpg"
import StoryImage from "../images/scott-graham-5fNmWej4tAA-unsplash.jpg"

import Navlinks from "../../Utils/Navlinks.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}

      <Navlinks color="black"/>
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We have 3 priorities."
        description=""
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Customer Satisfaction",
            description: "Understanding & assisting the customer as soon as any difficulty arise."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "User Experience",
            description: "Paying attention to the pleasant experience customers will have on the tool with the most important features to fulfill their needs"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Community",
            description: "Building a Marketplace where the users may share scenarios is also our way to bring value to the customers"
          },
        ]}
        linkText=""
      />

      <MainFeature1
        subheading={<Subheading>Our Mission</Subheading>}
        heading="XKyre is where Soft Skills thrive."
        description="We aim to provide companies globally with specialized tools focusing on Soft Skills assessment, training & growth. Soft Skills are a blurry area where it is difficult to assess properly without living the moment"
        buttonRounded={false}
        primaryButtonText="See Portfolio"
        imageSrc={MissionImage}
      />
      <MainFeature1
      // marre des processus déshumanisé et aux questions qui se répétent, IA c'est bien, on prend le role-play, la mise en situtation, la simulation collaborative pour faire émerger un nouveau mode de recrutement virtuel 
        subheading={<Subheading>Our Story</Subheading>}
        heading="We create the tool we also need."
        description="We experienced hiring and Skills assessment both from the assessed point of view and the recruiter point of view. Our jobs today require substantial investments of human skills in order for the team to be effective which may be difficult to assess."// It is painful both ways, espacially when we discover the way some hired candidates handle some very important situations. "
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc={StoryImage}
        textOnLeft={false}
      />
      <div className="my-32"></div>
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
