import axios from "axios";
const APISERVER = process.env.REACT_APP_BACKEND_API_HOST;
const http = axios.create({
    //baseURL: `${APISERVER}/api/${USER}`,
    baseURL: `${APISERVER}`,
    headers: {
      "Content-type": "application/json"
    }
});

const create = data => {
    return http.post("/api/application", data);
};

const getAll = () => {
    return http.get("/api/application");
  };
  
const get = id => {
return http.get(`/api/application/${id}`);
};

const update = (id, data) => {
return http.post(`/api/application/${id}`, data);
};

const remove = id => {
return http.delete(`/api/application/${id}`);
};

const removeAll = () => {
return http.delete(`/api/application`);
};

const findByFirebaseId = firebaseid => {
return http.get(`/api/application?uid=${firebaseid}`);
};

const findAllByJPIdList = listOfJPID => {
  return http.get(`/api/application/jpidlist?list=${listOfJPID}`);
};

const findAllByJPIdListWithCandidateInfo = listOfJPID => {
  return http.get(`/api/application/jpidlistwithcandidateinfo?list=${listOfJPID}`);
};



export default {
getAll,
get,
create,
update,
remove,
removeAll,
findByFirebaseId,
findAllByJPIdList,
findAllByJPIdListWithCandidateInfo
};