import React, { useEffect, useState, useRef } from "react";
import Activity from "./Activity";

//API AXIOS CONNECTION TO BACKEND
// import kpiService from "../../../Services/kpi.service";
// import jpService from "../../../Services/jobposition.service";
import Mod_AddPositionToQuickAccess from "../../../Modals/Mod_AddPositionToQuickAccess";

//props : uid (unused) & hrid & test & parentcallback & currentJPList
export default function AppJPPanel(props){
    // const USER = props.uid;

    //JP DATA
    let [JPData, setJPData] = useState(null);
    let [JPQuickAccessData, setJPQuickAccessData] = useState(null);

    // let [triggerTimeIcon, setTriggerTimeIcon] = useState(StartButton);
    const [showModalPosition, setShowModalPosition] = useState(false);
    // const [showErrorModal, setShowErrorModal]  = useState(false);
    // let [currentSelectedValue, setCurrentSelectedValue] = useState();

    //Job Position DATA INTERACTION
    useEffect(()=>{ //TempSolution : in order to rerender when changing activity
        if(props.jobpositions){
            setJPData(props.jobpositions)
            setJPQuickAccessData(props.jobpositions.filter(jpobj => {
                return jpobj.QuickAccess == "True";
            }))
        }
    }, [props.jobpositions])

    //UPDATE QUICKACCESS DATA
    function updateJPData(jpID, newData){
        // setJPData(newData)
        // setJPQuickAccessData(newData.filter(obj => {
        //     return obj.QuickAccess == "True";
        // }))
        props.updateQuickAccessInJPData(jpID, newData);
    }

    //QUICKACCESS MODIFICATION
    function handleQuickAccessUserInteraction(typeOfInteraction, value)
    {
        if(typeOfInteraction=="Select"){
            if(document.getElementById('activitypanel').contains(document.querySelector("input[name=act]:checked")))
            {
                props.selectedPositionParentCallback(JPQuickAccessData[document.querySelector("input[name=act]:checked").value]._id)
            }
        }
        else if(typeOfInteraction=="NewActivity")
        {
            setShowModalPosition(true);
        }
        else if(typeOfInteraction=="Delete")
        {
            RemovePositionFromQuickAccess(JPQuickAccessData[value]._id)
        }
        
        document.querySelectorAll("input[name=act]:checked").forEach((e)=>{e.checked=false;})

        // else if(typeOfInteraction=="Renaming")
        // {
        //     // activityrenaming
        //     let currentSelectedActivityName = JPData[value].Jobpositiontitle;
        //     let usersModifiedActivityName = prompt("What name would you give to the selected job position (10 characters max)", currentSelectedActivityName);
        //     if (usersModifiedActivityName != null) {
        //         usersModifiedActivityName = usersModifiedActivityName.substr(0, 10);
        //         let JPData_temp = [...JPData]
        //         JPData_temp[value].Jobpositiontitle = usersModifiedActivityName
        //         writeJobPositionData(JPData_temp);
        //     }
        // }
        // else if(typeOfInteraction=="Goal")
        // {
        //     // goalmodification
        //     let currentSelectedTimeGoal = JPData[value].Approvalgoal;
        //     let usersModifiedTimeGoal = prompt("What approval goal would you give to the selected job position (3 digits max)", currentSelectedTimeGoal);
        //     if (usersModifiedTimeGoal != null && !isNaN(usersModifiedTimeGoal)) {
        //         usersModifiedTimeGoal = usersModifiedTimeGoal.substr(0,3);
        //         let JPData_temp = [...JPData]
        //         JPData_temp[value].Approvalgoal = usersModifiedTimeGoal
        //         writeJobPositionData(JPData_temp);
        //     }
        // }
        
    }
    
    function AddPositionToQuickAccess(jpID){
        setShowModalPosition(false)

        if(jpID != null){
            
            let JPDataTemp = [...JPData];

            let indexOfPositionToQuickAccess = JPDataTemp.findIndex(posobj => {return posobj._id==jpID})
            JPDataTemp[indexOfPositionToQuickAccess].QuickAccess = "True";
            
            updateJPData(jpID, JPDataTemp[indexOfPositionToQuickAccess])
        }
        // writeJobPositionData(newJPData);
    }

    function RemovePositionFromQuickAccess(jpID){
        let JPDataTemp = [...JPData];
        let indexOfPositionToQuickAccess = JPDataTemp.findIndex(posobj => {return posobj._id==jpID})
        JPDataTemp[indexOfPositionToQuickAccess].QuickAccess = "False";
        
        updateJPData(jpID, JPDataTemp[indexOfPositionToQuickAccess])
        props.selectedPositionParentCallback("")
    }



    //REACT RENDER
    return (
        <div className="AppJPPanel DisappearingPanel">
            <div id="activitypanel">
                {showModalPosition ? <Mod_AddPositionToQuickAccess handleAddJobPositionToQuickAccess={AddPositionToQuickAccess} jobpositions={JPData}/> :<></>}
            
                {/* <img id="sloth" src={SlothImage} /> */}
                {/* <div id="slothtext">meow {username}</div> */}
                <div className="activitiestitle"> Quick Access </div>
                
                {!JPQuickAccessData ? "Loading..." : 
                <div className="activities" onClick={()=>handleQuickAccessUserInteraction("Select",null)}>
                    {!JPQuickAccessData[0]?<Activity parentCallback={handleQuickAccessUserInteraction} value="0" activity="" timespent={-1} timegoal={0} />   :   <Activity parentCallback={handleQuickAccessUserInteraction} value="0" activity={JPQuickAccessData[0].Jobpositiontitle} timespent={JPQuickAccessData[0].StepsKPIs.Approved} timegoal={JPQuickAccessData[0].Approvalgoal} />}
                    {!JPQuickAccessData[1]?<Activity parentCallback={handleQuickAccessUserInteraction} value="1" activity="" timespent={-1} timegoal={0} />   :   <Activity parentCallback={handleQuickAccessUserInteraction} value="1" activity={JPQuickAccessData[1].Jobpositiontitle} timespent={JPQuickAccessData[1].StepsKPIs.Approved} timegoal={JPQuickAccessData[1].Approvalgoal} />}
                    {!JPQuickAccessData[2]?<Activity parentCallback={handleQuickAccessUserInteraction} value="2" activity="" timespent={-1} timegoal={0} />   :   <Activity parentCallback={handleQuickAccessUserInteraction} value="2" activity={JPQuickAccessData[2].Jobpositiontitle} timespent={JPQuickAccessData[2].StepsKPIs.Approved} timegoal={JPQuickAccessData[2].Approvalgoal} />}
                    {!JPQuickAccessData[3]?<Activity parentCallback={handleQuickAccessUserInteraction} value="3" activity="" timespent={-1} timegoal={0} />   :   <Activity parentCallback={handleQuickAccessUserInteraction} value="3" activity={JPQuickAccessData[3].Jobpositiontitle} timespent={JPQuickAccessData[3].StepsKPIs.Approved} timegoal={JPQuickAccessData[3].Approvalgoal} />}
                    {!JPQuickAccessData[4]?<Activity parentCallback={handleQuickAccessUserInteraction} value="4" activity="" timespent={-1} timegoal={0} />   :   <Activity parentCallback={handleQuickAccessUserInteraction} value="4" activity={JPQuickAccessData[4].Jobpositiontitle} timespent={JPQuickAccessData[4].StepsKPIs.Approved} timegoal={JPQuickAccessData[4].Approvalgoal} />}
                </div>}
                
            </div>

        </div>
    );
  };

