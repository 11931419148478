import React from 'react';
import DoughnutChart_2 from './DoughnutChart_2';
import { useState, useEffect } from 'react';

// Import utilities
// import { tailwindConfig } from '../../utils/Utils';

import resolveConfig from 'tailwindcss/resolveConfig';
const tailwindConfig = () => {
    // Tailwind config
    return resolveConfig('./src/css/tailwind.config.js')
}

export default function AppKPI_2(props) {
  let [chartData, setChartData]=useState({});

  useEffect(()=>{
    let data;
    if(!props.jobpositions || props.jobpositions.length==0){
      data=[0, 0, 0]
    }
    else{
      data=[
        props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Approved, 0),
        props.jobpositions.reduce((total, next) => total + next.StepsKPIs.Rejected, 0),
        props.jobpositions.reduce((total, next) => total + next.Total - next.StepsKPIs.Approved - next.StepsKPIs.Rejected, 0),
      ]
    }
      setChartData({
        labels: ['Approved', 'Rejected', 'Ongoing'],
        datasets: [
          {
            label: 'Hiring Decision',
            data: data,
            backgroundColor: [
              tailwindConfig().theme.colors.green[500],
              tailwindConfig().theme.colors.red[400],
              tailwindConfig().theme.colors.indigo[800],
            ],
            hoverBackgroundColor: [
              tailwindConfig().theme.colors.green[600],
              tailwindConfig().theme.colors.red[500],
              tailwindConfig().theme.colors.indigo[900],
            ],
            hoverBorderColor: tailwindConfig().theme.colors.white,
          },
        ],
      })
    ;
  },[props.jobpositions])

  return (
    <>
    {chartData.labels && <div className="flex flex-col w-1/3 col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-xs text-gray-500">Current Hiring Decisions </h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
       <DoughnutChart_2 data={chartData} /> {/* width={389} height={260} /> */}
    </div>}
    </>
  );
}