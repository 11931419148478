
import firebase from '../../Services/firebase.service';
import UserOptionsButton from './UserOptionsButton.js';


export default function AppOptions(props){

    // function showSubPanel(elementToOpen){
    //     // Close the subpanel when the users clicks somewhere else
    //     window.onclick = function(event) {
    //         if (!event.target.matches("."+elementToOpen+">*")) {
    //             var showedSubPanels = document.getElementsByClassName("subpanel show");
    //             for (let i = 0; i < showedSubPanels.length; i++) {
    //                 showedSubPanels[i].classList.remove('show');
    //             }
    //         }
    //     }

    //     document.querySelector("."+elementToOpen+">.subpanel").classList.toggle("show");
    // }

    function handleLogout(){
        localStorage.clear();
        firebase.logoutUser();
        window.location.href = "/";
    }

    return(
        <div className="AppOptions"> 
            <div className="top">
                {/* <div className="people"><img src={svg_people}/>
                    <div className="subpanel">
                        <a className="peoplebtn" href="#">Unavailable yet</a>
                        <a className="peoplebtn" href="#">We're working on it</a>
                    </div>
                </div>
                <div className="notification" >   onClick={() => {showSubPanel("notification")}}> 
                    <img src={svg_notification}/>
                    <div className="subpanel">
                        <a className="notificationbtn" href="#">Don't forget to check the KPIs!!</a>
                        <a className="notificationbtn" href="#">Give us feedback on the tool</a>
                    </div>
                </div> */}

                {/* <button
                    className="bg-white text-vorkv active:bg-pink-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={() => setShowModal(true)}
                >
                    Sync
                </button>

                <button
                    className="bg-vorkv text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={() => setShowModal(true)}
                >
                    Save
                </button> */}
                <UserOptionsButton handleLogout={handleLogout} user={props.user}/> 
            
                
                {/* <div className="username">{props.user.substr(0,25)}
                    <div className="subpanel">
                        <a className="optionbtn" href="#">Account</a>    
                        <a className="optionbtn" href="/auth/login" onClick={handleLogout}>Log out</a>    
                    </div>
                </div> */}
            </div>
            {/* <div className="bottom">
                <div className="sound">
                    <div className="title">
                        <div className="img"><img src={svg_sound}/></div>
                        <div className="txt">Background music</div>
                    </div>
                    <div className="audio">
                        <a href="https://open.spotify.com/" target="_blank" rel="noopener noreferrer"><img src={svg_spotify}/></a>
                        <a href="https://music.apple.com/" target="_blank" rel="noopener noreferrer"><img src={svg_applemusic}/></a>
                        <a href="https://music.youtube.com/explore" target="_blank" rel="noopener noreferrer"><img src={svg_youtube}/></a>
                    </div>
                </div>
            </div> */}
        </div>
    )
}