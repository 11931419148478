import logo from './logo.svg';
import './App.css';
import {TW_Footer} from './Components/TailwindComponents/TW_Footer';

import Landing from './Pages/Landing.js';
import AppNavBar from './Components/EveryScreen/AppNavBarV2';
// import AppQuote from './Components/ActivityScreen/AppQuote';
import AppOptions from './Components/EveryScreen/AppOptions';
import AppGrid from './Components/ActivityScreen/AppGrid';
import AppJPPanel from './Components/ActivityScreen/HRWorkspace/AppJPPanel';
import Account from './Pages/Account'
import TabCardGrid from './Components/TailwindTreact/components/cards/TabCardGrid';

import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
import { TW_Signup } from './Pages/TW_Signup';
import { TW_Login } from './Pages/TW_Login';

import AboutUs from './Components/TailwindTreact/pages/AboutUs';
import Pricing from './Components/TailwindTreact/pages/Pricing';
import ContactUs from './Components/TailwindTreact/pages/ContactUs';
// -
import { v4 as uuidV4 } from "uuid";
import Product from './Pages/Product';

//API AXIOS CONNECTION TO BACKEND
import firebase from './Services/firebase.service';
import { GoogleAuthProvider } from "firebase/auth";
import hrService from "./Services/hr.service";
import { useParams } from 'react-router';
import TermsOfService from './Components/TailwindTreact/pages/TermsOfService';
import PrivacyPolicy from './Components/TailwindTreact/pages/PrivacyPolicy';
import Disclaimer from './Components/TailwindTreact/pages/Disclaimer';

import Mod_UserWalkthrough from './Modals/Mod_UserWalkthrough'



function App(props) {
  let [userid, setUserId] = useState();
  let [usermail, setUserMail] = useState();
  let history = useHistory();
  let [switchtimerroom, setSwitchTimerRoom] = useState("room")
  let [selectedjobpositionid, setSelectedJobPositionId] = useState("");
  let [currentJPlist, setCurrentJPList] = useState([]);
  let [hrID, setHRID] = useState("");
  let [hrData, setHRData] = useState(null);

  function initializeHRData(username, uid){
    let hrinitdata = {
      Firstname: "test",
      Lastname: "test",
      Email: username,
      CurrentjobpositionsID: [],
      SubscriptionID: "",
      KpiID: "",
      FirebaseID: uid,
    }
    hrService.create(hrinitdata);
  }

  function getHRData(firebaseId){
    hrService.findByFirebaseId(firebaseId)
    .then((res) => {
        if(res.status!=200){
            alert("User not found, you'll be redirected to the Signup page"); window.location.href='/auth/signup'; 
        }
        else{
            setCurrentJPList(res.data[0].CurrentjobpositionsID);
            setHRID(res.data[0]._id);
            setHRData(res.data[0]);
            // setDataId(res.data[0]._id);
        }
    });
  }

  function updateHRData(newDataid, newData){
    hrService.update(newDataid, newData).then(()=>{console.log("ok")}).catch((err)=>{alert("update HR :"+err)});
  }

  useEffect(()=>{
    firebase.connectFirebase().then((instance) => {
        if (instance) {
            // console.log("CONNECTED TO FIREBASE")
          firebase.getAuth(); //For Google
        }
     })
    // .catch(()=>{
    //   history.push("/error")
    //   alert("Error : Connection Failed");
    //})
    ;
  }, [])    

  useEffect(()=>{
    //Login in with Google
    if(localStorage.getItem('AuthenticationMode')=="Google"){
      GettingAuthInformationBackFromGoogleAndConnecting(localStorage.getItem('AuthenticationType'));
      localStorage.removeItem('AuthenticationMode');
      localStorage.removeItem('AuthenticationType');
    }
    else{
      //If no login we delete the firebase localDB just to be sure that there are no data
      indexedDB.deleteDatabase("firebaseLocalStorageDb") 
      //Storing the initial url in order to go back to it after login if necessary
      // if(!window.location.pathname.startsWith("/auth/"))//In order not to change userlink with the pathname of the login screen
      // {setUserLink(window.location.pathname)}
    }
  }, [])

  function GettingAuthInformationBackFromGoogleAndConnecting(AuthenticationType){
        const auth = firebase.getAuth();
        console.log("GettingAuthInf...")
        console.log(auth)
        firebase.getRedirectResult(auth)
        // const provider = new GoogleAuthProvider();
        // firebase.signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access Google APIs.
                // const credential = GoogleAuthProvider.credentialFromResult(result);
                // const token = credential.accessToken;
                const user = result.user;
                if(AuthenticationType=="Signup"){initializeHRData(user.displayName,user.uid)}
                historyUserSign(user.uid, user.email);
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
  }

  function TW_LoginCallback(mail, password, uidIfLocalStorageLogin, GoogleOrFacebookLogin, previoususerlink){
    if(uidIfLocalStorageLogin){historyUserSign(uidIfLocalStorageLogin, mail, previoususerlink);}
    else if(GoogleOrFacebookLogin){
        if(GoogleOrFacebookLogin=="Google"){
          firebase.SignWithGoogle().then(()=>{
            localStorage.setItem('AuthenticationMode',"Google")
            localStorage.setItem('AuthenticationType',"Signin")
          }).catch(()=>{
            alert("Error : Authentication Failed, please try again later");
          })
        }
    }
    else{
      // console.log("mail : "+mail)
      // console.log("password : "+password)
      firebase.signUser(mail, password).then((authdata) => {
          if(!authdata){alert("NO USER FOUND")}
          else{
            historyUserSign(authdata.user.uid, mail, previoususerlink);
          }
      }).catch(()=>{
        alert("Error : Authentication Failed, please try again later");
      })
    }
  } 

  function TW_SignupCallback(username, mail, password, GoogleOrFacebookSignup){
      if(GoogleOrFacebookSignup){
        if(GoogleOrFacebookSignup=="Google"){
          firebase.SignWithGoogle().then(()=>{
            localStorage.setItem('AuthenticationMode',"Google")
            localStorage.setItem('AuthenticationType',"Signup")
          }).catch(()=>{
            alert("Error : Authentication Failed, please try again later");
          })
        }
      }
      else{
        firebase.createUser(mail, password).then((authdata) => {
          if(!authdata) {alert("Account exists, Please choose another username")}
          else{
              initializeHRData(username, authdata.user.uid)
              historyUserSign(authdata.user.uid, mail, null);
          }
        }).catch(()=>{
          alert("Error : Authentication Failed, please try again later");
        })
      }
  }

  function historyUserSign(firebaseid, mail, previoususerlink){
    if (firebaseid != null) {
      setUserId(firebaseid);
      setUserMail(mail);
      getHRData(firebaseid);
      // store the user in localStorage
      localStorage.setItem('userid', firebaseid)
      localStorage.setItem('usermail', mail)

      if(previoususerlink == null || previoususerlink.startsWith("/auth")){history.push("/workspace")}
      else{history.push(previoususerlink)}
    }
  }

  

  function NavBarCallback(menuChoice){
    if(menuChoice != null){
      setSelectedJobPositionId("")
      history.push(menuChoice);
    }
  }

  function startOrEndProductTour(StartOrEnd){
    if(StartOrEnd=="start"){history.push("/demo/workspace/producttour");}
    else{history.push("/demo/workspace");}
  }

  // window.addEventListener('resize', SwitchBetweenTimerAndRoomsWhenMobile)

  function SwitchBetweenTimerAndRoomsWhenMobile(){
    if(window.innerWidth<820){
      if(switchtimerroom == "room"){
        if(document.querySelector('.AppCentralPanel') || document.querySelector('.AppJPPanel'))
        {setSwitchTimerRoom("timer")
        document.querySelector('.AppCentralPanel').classList.remove("AppearingPanel")
        document.querySelector('.AppCentralPanel').classList.add("DisappearingPanel")
        document.querySelector('.AppJPPanel').classList.remove("DisappearingPanel")
        document.querySelector('.AppJPPanel').classList.add("AppearingPanel")}
      }
      else{
        if(document.querySelector('.AppCentralPanel') || document.querySelector('.AppJPPanel'))
        {setSwitchTimerRoom("room")
        document.querySelector('.AppJPPanel').classList.remove("AppearingPanel")
        document.querySelector('.AppJPPanel').classList.add("DisappearingPanel")
        document.querySelector('.AppCentralPanel').classList.remove("DisappearingPanel")
        document.querySelector('.AppCentralPanel').classList.add("AppearingPanel")}
      }
    }
  }

  function AppJPPanelSelectedPositionCallback(clickedjobpositionid){
    setSelectedJobPositionId(clickedjobpositionid);
  }

  function AppJPPanelUpdateCurrentJPListCallback(newCurrentJPList){
    setCurrentJPList(newCurrentJPList)
    let newData = JSON.parse(JSON.stringify(hrData));
    newData.CurrentjobpositionsID = newCurrentJPList;
    updateHRData(newData._id, newData)
  }


  return (
      <Switch>

        <Route path="/" exact>
          <Redirect to="/home/" />
        </Route>
        <Route path="/error" exact>
          An error has occured.
        </Route>

        {/************** LANDING PAGE & DEMO ****************/}
        {/* <Route path="/about/" exact> <AboutUs /> </Route> */}
        {/* <Route path="/product/" exact> <Product /> </Route> */}
        {/* <Route path="/pricing/" exact> <Pricing /> </Route> */}
        <Route path="/contact/" exact> <ContactUs /> </Route>
        <Route path="/termsofservice" exact> <TermsOfService /> </Route>
        <Route path="/privacypolicy" exact> <PrivacyPolicy /> </Route>
        <Route path="/disclaimer" exact> <Disclaimer /> </Route>
        <Route path="/home/" exact>
          <Landing />
          {/* <AppNavBar parentCallback={NavBarCallback} menu="menu1"/> */}
        </Route>
        <Route path="/demo/marketplace" exact>

                  <div className="app">

                    <div className="MarketplaceGrid">
                      {/* upleft : quote
                          upright : options
                          mainleft : AppGrid
                          mainright : activitypanel
                      */}
                      <TabCardGrid />
                      {/* <AppOptions uid={"vork-v@gmail.com"}/>
                      <button id="SwitchTimerRoomsBtn" onClick={SwitchBetweenTimerAndRoomsWhenMobile}>Switch between the timer & the rooms</button>
                      <AppGrid JobPositionId={selectedjobpositionid} />
                      <AppJPPanel uid="test" test="true" selectedPositionParentCallback={AppJPPanelSelectedPositionCallback}/> */}
                    </div>
                    <AppNavBar parentCallback={NavBarCallback} menu="menu2" isdemo={true}/>
            </div>
          </Route>

        <Route path="/demo/workspace" exact>
            <div className="app">
              {/* <Mod_UserWalkthrough isItTheRightUrl={false} startOrEndProductTour={startOrEndProductTour}/> */}
              <AppOptions test="true" user={"contact@xkyre.com"}/>
                {/* upleft : quote
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                {/* <button id="SwitchTimerRoomsBtn" onClick={SwitchBetweenTimerAndRoomsWhenMobile}>Switch between the timer & the rooms</button> */}
                <AppGrid test="true" JobPositionId={selectedjobpositionid} selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} hrID="test" updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/>
                {/* <AppJPPanel uid="test" hrid="test" test="true" selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/> */}
              
              <AppNavBar parentCallback={NavBarCallback} menu="menu1" isdemo={true}/>
            </div>
        </Route>

        <Route path="/demo/workspace/producttour" exact>
            <div className="app">
              {/* <Mod_UserWalkthrough isItTheRightUrl={true}  startOrEndProductTour={startOrEndProductTour}/> */}
              {/* <div className="AppGrid"> */}
                {/* upleft : quote
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="true" user={"contact@xkyre.com"}/>
                {/* <button id="SwitchTimerRoomsBtn" onClick={SwitchBetweenTimerAndRoomsWhenMobile}>Switch between the timer & the rooms</button> */}
                <AppGrid test="true" JobPositionId={selectedjobpositionid} selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} hrID="test" updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/>
                {/* <AppJPPanel uid="test" hrid="test" test="true" selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/> */}
              {/* </div> */}
              <AppNavBar parentCallback={NavBarCallback} menu="menu1" isdemo={true}/>
            </div>
        </Route>

        <Route path="/demo/kpis/">
            <div className="app">
              <div className="AppGrid">
                {/* upleft : quote --> now in AppGrid
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="true" user={"contact@xkyre.com"}/>
                <AppGrid test="true" JobPositionId={selectedjobpositionid} selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} hrID={hrID}/>
              </div>
              <AppNavBar parentCallback={NavBarCallback} menu="menu3" isdemo={false}/>
            </div>
        </Route>

        <Route path="/demo/account" exact>
            <div className="app">
              <div className="AppGrid">
                {/* upleft : quote
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="true" user={"contact@xkyre.com"}/>
                <Account />
              </div>
              <AppNavBar parentCallback={NavBarCallback} menu="menu3" isdemo={true}/>
            </div>
        </Route>

        <Route path="/demo/assess/:id" exact>
            <div className="app">
              <div className="AssessGrid">
                <AppOptions test="true" user={"contact@xkyre.com"}/>
                <AppGrid test="true" JobPositionId={selectedjobpositionid} />
                
              </div>
              <AppNavBar parentCallback={NavBarCallback} menu="menu1" isdemo={true}/>
            
            </div>
        </Route>

        
        {/************** Authentication ****************/}
        {/* <Route path="/auth/" exact>
            <Redirect to={{pathname: "/auth/login/", userlink: "/workspace"}} />
        </Route>
        <Route path="/auth/login/" exact>
            <TW_Login parentCallback={TW_LoginCallback}/>
        </Route>
        <Route path="/auth/signup/" exact>
            <TW_Signup parentCallback={TW_SignupCallback}/>
        </Route> */}

        {/************** REAL APP ****************/}
        {/* <Route path="/redirect" exact>
          <Redirect to={`/liveroom${uuidV4()}`} />
        </Route> */}
        <Route path="/workspace/">
          {!userid ? <Redirect to={{pathname: "/auth/login/", userlink: "/workspace"}} /> :
            <div className="app">
              {/* <div className="AppGrid"> */}
                {/* upleft : quote --> now in AppGrid
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="false" user={usermail}/>
                {/* <button id="SwitchTimerRoomsBtn" onClick={SwitchBetweenTimerAndRoomsWhenMobile}>Switch between the timer & the rooms</button> */}
                <AppGrid test="false" JobPositionId={selectedjobpositionid} selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} hrID={hrID} updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/>
                {/* <AppJPPanel uid={userid} hrid={hrID} test="false" selectedPositionParentCallback={AppJPPanelSelectedPositionCallback} currentJPList={currentJPlist} updateCurrentJPListCallback={AppJPPanelUpdateCurrentJPListCallback}/> */}
              {/* </div> */}
              <AppNavBar parentCallback={NavBarCallback} menu="menu1" isdemo={false}/>
            </div>
          }
        </Route>

        <Route path="/marketplace" exact>

                  <div className="app">

                    <div className="MarketplaceGrid">
                      {/* upleft : quote
                          upright : options
                          mainleft : AppGrid
                          mainright : activitypanel
                      */}
                      <TabCardGrid />
                      {/* <AppOptions uid={"vork-v@gmail.com"}/>
                      <button id="SwitchTimerRoomsBtn" onClick={SwitchBetweenTimerAndRoomsWhenMobile}>Switch between the timer & the rooms</button>
                      <AppGrid JobPositionId={selectedjobpositionid} />
                      <AppJPPanel uid="test" test="true" selectedPositionParentCallback={AppJPPanelSelectedPositionCallback}/> */}
                    </div>
                    <AppNavBar parentCallback={NavBarCallback} menu="menu2" isdemo={false}/>
            </div>
          </Route>

        <Route path="/kpis/">
          {!userid ? <Redirect to={{pathname: "/auth/login/", state: { userlink: "/kpis" }}} /> :
            <div className="app">
              <div className="AppGrid">
                {/* upleft : quote --> now in AppGrid
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="false" user={usermail}/>
                We're working on the KPI screen
              </div>
              <AppNavBar parentCallback={NavBarCallback} menu="menu3" isdemo={false}/>
            </div>
          }
        </Route>

        <Route path="/account/">
          {!userid ? <Redirect to={{pathname: "/auth/login/", userlink: "/account"}} /> :
            <div className="app">
              <div className="AppGrid">
                {/* upleft : quote --> now in AppGrid
                    upright : options
                    mainleft : AppGrid
                    mainright : activitypanel
                */}
                <AppOptions test="false" user={usermail}/>
                We're working on the account screen
              </div>
              <AppNavBar parentCallback={NavBarCallback} menu="menu4" isdemo={false}/>
            </div>
          }
        </Route>

        <Redirect to="/home"/>
      </Switch>
  );
}

export default App;


/*
Components :
  Header: 
    Menu
  ActivityPanel:
    Timer
    Activity
    KPIs
    SocialMedia
  AppGrid
  Footer



// Function Component Template
 
export default function App (){
  const [value, setValue] = React.useState(
    localStorage.getItem('myValueInLocalStorage') || '',
  );
 
  React.useEffect(() => {
    localStorage.setItem('myValueInLocalStorage', value);
  }, [value]);
 
  const onChange = event => setValue(event.target.value);
 
  return (
    <div>
      <h1>Hello React Function Component!</h1>
 
      <input value={value} type="text" onChange={onChange} />
 
      <p>{value}</p>
    </div>
  );
};

*/