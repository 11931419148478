import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import logo from "../../Assets/Images/logos/white_logo_transparent_background.png"

import AppNavBarGroup from './AppNavBarV2Group';


import {gsap} from 'gsap';

import menu1 from "../../Assets/Images/navbar/monitor.svg"
import menu2 from "../../Assets/Images/navbar/shopping-bag.svg"
import menu3 from "../../Assets/Images/navbar/user-circle.svg"
import menu4 from "../../Assets/Images/navbar/bell.svg"
import menu5 from "../../Assets/Images/navbar/credit-card.svg"
import menu6 from "../../Assets/Images/navbar/help-circle.svg"
import menu7 from "../../Assets/Images/navbar/compass.svg"
function SingleElement(props){
    return(
        <li className={`px-2 py-2 rounded mb-0.5 last:mb-0 ${props.menuId == props.selectedMenu && 'bg-primary-900 bg-opacity-50'} ${props.menuId != props.selectedMenu && 'hover:bg-primary-900 hover:bg-opacity-25'} ${!props.available && 'text-gray-300 cursor-not-allowed opacity-50'}  `}>
            <button onClick={()=>{props.parentCallback(props.menuId)}} className={`block text-slate-200 hover:text-white truncate transition duration-150 ${!props.available && 'cursor-not-allowed'}`}>
                  <div className="flex">
                    <img className="shrink-0 w-5 h-5" src={props.icon} />
                    {props.sidebarExpanded && <span className="text-sm font-medium ml-3 duration-200">{props.elementTitle}</span>}
                  </div>
            </button>
        </li>
    )
}

export default function AppNavBar(props) {

  let [menuChoice, setMenuChoice] = useState(props.menu);
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);



    function Animation_LoadingScreen(){
        let tl=gsap.timeline();
        tl.set('.loadingContainer', {
            display: "flex",
            opacity:"1"
        })
    
        // tl.fromTo('.loadingtxt',{ 
        //   delay:2,
        //   opacity: 1, 
        //   ease: "power1.in",
        // },{
        //   opacity: 0,
        //   delay: 2,
        // })
        
        tl.to('.loadingScreen', {
            duration: 1,
            // left: '100%',
            opacity: 0, 
            delay: .8,
            ease: "power1.out",
        })
    
        tl.set('.loadingContainer', {
            display: "none",
        })

        tl.to('.loadingScreen', {
            duration: 1,
            // left: '0%',
            opacity:1,
            delay: .5,
            ease: "power1.out",
        })
    
      }
    
      useEffect(()=>{
        Animation_LoadingScreen();
      },[])





  useEffect(() => {
    document.querySelector('#sidebar').addEventListener("mouseover", function( event ) {
        setSidebarExpanded(true);
    })
    let outerspace = document.querySelector('.MarketplaceGrid') || document.querySelector('.AppGrid')
    outerspace.addEventListener("mouseover", function( event ) {
        setSidebarExpanded(false);
    })

  },[])

  useEffect(() => {
    if (sidebarExpanded) {
      document.querySelector('#sidebar').classList.add('w-64');
    } else {
      document.querySelector('#sidebar').classList.remove('w-64');
    }
  }, [sidebarExpanded]);



  function checkSelectedPage(menuId){
    let nexturl = "";  
    switch (menuId) {
        case "menu1": props.isdemo ? nexturl = "/demo/workspace" : nexturl = "/workspace"; break;
        case "menu2": props.isdemo ? nexturl = "/demo/marketplace" : nexturl = "/marketplace"; break;
        case "menu3": props.isdemo ? nexturl = null : nexturl = null; break;//"/demo/account" : nexturl = "/account"; break;
        case "menu4": props.isdemo ? nexturl = null : nexturl = null; break;
        case "menu5": props.isdemo ? nexturl = null : nexturl = null; break;
        case "menu6": props.isdemo ? nexturl = "/contact" : nexturl = null; break;
        case "menu7": props.isdemo ? nexturl = "/" : nexturl = "/"; break;
      }
    if(nexturl){NavigateToSelectedPage(nexturl, menuId);}
  }

  function NavigateToSelectedPage(futureMenuPage, menunumber){
        
        setMenuChoice(menunumber)
        
        // if(futureMenuPage=="/home"){props.parentCallback(futureMenuPage);}
        if(props.isdemo){props.parentCallback(futureMenuPage);}
        else if(CheckIfUserAlreadySigned()){
            props.parentCallback(futureMenuPage);
        }
        else{
            props.parentCallback("/auth/login");
        }
    }

    function CheckIfUserAlreadySigned(){
        const CheckIfLoggedInUser = localStorage.getItem("userid");
        console.log(CheckIfLoggedInUser)
        if (CheckIfLoggedInUser) {
        const userid = CheckIfLoggedInUser
        const usermail = localStorage.getItem("usermail")
        return true
        }
    }


  return (
    <div>
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col text-white z-40 left-0 top-0 fixed lg:translate-x-0 transform w-16 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar bg-vorkv p-4 transition-all duration-200 ease-in-out`} //  ${sidebarExpanded ? 'translate-x-0' : '-translate-x-64'}
      >


        {/* Sidebar header */}
        <div className="flex justify-center my-10">
          {/* Logo */}
          <NavLink end to="/" className="block">
          {sidebarExpanded ? <img src={logo} className="h-20 w-24"/> : <img src={logo} className="h-8 w-10"/> }
          </NavLink>
        </div>

{/* Workspace : main candidates
    Marketplace : buy roleplays
    My Account : change name, mail, etc...
    Notifications : what do i receive
    Subscription/Billing : Change Plan, see how much i have left, ...
    Support : Display Phone number, mail etc...
    Landing Page : Go back to landing

    
    */}


        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-white font-semibold pl-1">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">____</span>
            </h3> 
            <ul className="mt-2">
                {/* Workspace */}
                <SingleElement menuId="menu1" available={true} selectedMenu={menuChoice} elementTitle="Workspace" parentCallback={checkSelectedPage} icon={menu1} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* Marketplace */}
                <SingleElement menuId="menu2" available={true} selectedMenu={menuChoice} elementTitle="Marketplace" parentCallback={checkSelectedPage} icon={menu2} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* My Account */}
                <SingleElement menuId="menu3" available={false} selectedMenu={menuChoice} elementTitle="My Account (No Demo)" parentCallback={checkSelectedPage} icon={menu3} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* Notifications */}
                <SingleElement menuId="menu4" available={false} selectedMenu={menuChoice} elementTitle="Notifications (No Demo)" parentCallback={checkSelectedPage} icon={menu4} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* Subscription/Billing */}
                <SingleElement menuId="menu5" available={false} selectedMenu={menuChoice} elementTitle="Subscription/Billing (No Demo)" parentCallback={checkSelectedPage} icon={menu5} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* Support */}
                <SingleElement menuId="menu6" available={true} selectedMenu={menuChoice} elementTitle="Support" parentCallback={checkSelectedPage} icon={menu6} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
                {/* Landing Page */}
                <SingleElement menuId="menu7" available={true} selectedMenu={menuChoice} elementTitle="Landing Page" parentCallback={checkSelectedPage}  icon={menu7} sidebarExpanded={sidebarExpanded} pathname={pathname}/>
            </ul>
            

          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 lg:inline-flex justify-end mt-auto">
          <div className="px-1 py-1">
            {/* <button onClick={() => setSidebarExpanded(!sidebarExpanded)}> */}
              <span className="sr-only">Expand / collapse sidebar</span>
              
              {!sidebarExpanded?
              <svg className="w-4 h-4 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
              :
              <svg className="w-4 h-4 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
              </svg>
              }
            {/* </button> */}
          </div>
        </div>

      </div>
    </div>
  );
}
